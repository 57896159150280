import React from 'react';
import {BACKOFFICE_BASE_URL, QR_CODE_ENDPOINT} from "../constants";
import './credential.css';
import { getDate } from '../helpers';
import {getMemberFullName, MemberBasic} from "../models/member";

interface Props {
  member: MemberBasic;
}

export default function Credential(props: Props) {
  return (
    <div className="outside credential">
      <div className="club-section">
        <div className="club-section-first-line">
          <p className="club-font">CLUB ATLÉTICO <strong>PACÍFICO</strong></p>
          <img className="club-logo" width="20%"
               src={`/pacifico.png`}/>
        </div>
      </div>
      <div className="main-section">
        <div className="picture-section">
          <img id="credential-member-picture" width="75%"
               src={props.member.imagePath ?? `/default-profile-picture.jpeg`}/>
          <div className="data-field-value">
            <div className="data-field data-field-member-picture">
              <p className="label">N° DE SOCIO</p>
              <p className="value">{props.member.memberNumber.toString().length > 0 ? props.member.memberNumber : "_____"}</p>
            </div>
          </div>
        </div>
        <div className="data-section">
          <div className="data-field-value">
            <div className="data-field">
              <p className="label">NOMBRE Y APELLIDO</p>
              <p className="value">{props.member.firstName.length > 0 || props.member.lastName.length > 0 ? `${getMemberFullName(props.member)}` : "___________________"}</p>
            </div>
          </div>
          <div className="data-field-value">
            <div className="data-field">
              <p className="label">DOCUMENTO</p>
              <p className="value">{props.member.documentNumber.toString().length > 0 ? props.member.documentNumber : "________"}</p>
            </div>
          </div>
          <div className="data-field-value">
            <div className="data-field">
              <p className="label">FECHA DE ALTA</p>
              <p className="value">{getDate(new Date(props.member.membershipDate), 'dd/mm/yyyy')}</p>
            </div>
          </div>
          <div className="data-field-value">
            <div className="data-field">
              <p className="label">CATEGORÍA</p>
              <p className="value">{props.member.type}</p>
            </div>
          </div>
        </div>
        <div className="qr-section">
          <span className="helper"></span>
          {
            props.member.id
              ? <img id="qr" width="90%" src={QR_CODE_ENDPOINT.replace('{MemberUrl}', `${BACKOFFICE_BASE_URL}/members/status/${props.member.id}`)}/>
              : ""
          }
        </div>
      </div>
      <div className="club-section-bottom">
        <div className="club-section-bottom-line">
          <p className="club-font-slogan">El primero de la ciudad</p>
        </div>
      </div>
    </div>
  )
}
