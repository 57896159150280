import {Button, Heading, List, ListIcon, ListItem, Text} from "@chakra-ui/react";
import React from "react";
import {Activity} from "../models/activity";
import {MdSportsFootball} from "react-icons/md";

interface Props {
  activities: Activity[];
  buttonActionOnClick: () => void;
}

export default function ActivitiesFeesForMembershipFee(props: Props) {
  return (
    <>
      <Heading as="h2" size="md">Aranceles de actividades</Heading>
      <Text>Junto con el pago de tus cuotas sociales, también estás abonando las siguientes actividades:</Text>
      <List spacing={3}>
        {
          props.activities.map(x =>
            !x.isInsurance ? <ListItem key={x.id}><ListIcon as={MdSportsFootball} />{x.name}</ListItem> : null
          )
        }
      </List>
      <Text>Si únicamente querés abonar las cuotas sociales, hacé click acá:</Text>
      <Button colorScheme="blue" onClick={props.buttonActionOnClick}>Abonar sólo cuotas sociales</Button>
    </>
  )
}
