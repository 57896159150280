import * as React from 'react';
import {
  Badge,
  Button,
  Center,
  Heading,
  HStack,
  Stack,
  Text, VStack
} from "@chakra-ui/react";
import { Card, CardBody } from '@chakra-ui/card';
import { useEffect, useState } from 'react';
import { Member } from '../models/member';
import { getUserFullName } from '../models/user';
import { useAuth } from '../components/auth';
import { useNavigate } from 'react-router-dom';
import { HTTP_STATUS_CODE_OK, MY_MEMBERSHIP_FEES_PAGE_ROUTE } from '../constants';
import { getMember } from '../services/member-service';
import { getMembershipFeesByMember } from '../services/product-service';
import MemberActivityDetails from "../components/member-activity-details";
import {isMobile} from "react-device-detect";

function Dashboard(): JSX.Element {
  const auth = useAuth();
  const navigate = useNavigate();
  const [username, setUsername] = useState(getUserFullName(auth.user));
  const [member, setMember] = useState<Member>();
  const [pendingMembershipFees, setPendingMembershipFees] = useState<number>();

  useEffect(() => {
    const getMemberData = async (): Promise<void> => {
      const member = await getMember(auth?.user?.selectedMember?.id);
      if (member != null) {
        setMember(member);
      }
    }

    const getMemberMembershipFeesData = async (): Promise<void> => {
      const memberMembershipFeesResponse = await getMembershipFeesByMember(auth?.user?.selectedMember?.id);
      if (memberMembershipFeesResponse.statusCode === HTTP_STATUS_CODE_OK) {
        setPendingMembershipFees(memberMembershipFeesResponse.response?.pendingMembershipFees ?? 0)
      }
    }

    getMemberData().catch(() => {});
    getMemberMembershipFeesData().catch(() => {});
    setUsername(getUserFullName(auth.user));
  }, [auth?.user?.selectedMember?.id]);

  const handleMembershipFeesButton = (): void => {
    navigate(MY_MEMBERSHIP_FEES_PAGE_ROUTE, { replace: true });
  }

  const membershipFeesStatusCard = (): JSX.Element => {
    const hasPendingMembershipFees = (pendingMembershipFees ?? 0) > 0;
    return (
      <Card direction="row" overflow="hidden" variant="filled" backgroundColor="gray.300" width={['100%', '100%', '50%']} alignSelf="start" >
        <Stack>
          <CardBody>
            <Heading size="md">Cuotas sociales</Heading>
            <HStack>
              <Text paddingY="2">Situación actual:</Text>
              {hasPendingMembershipFees ? (
                <Badge colorScheme="red">Deuda</Badge>
              ) : (
                <Badge colorScheme="green">Al día</Badge>
              )}
            </HStack>
            {hasPendingMembershipFees ? (
              <Text paddingY="2">
                Tenés{' '}
                <Text paddingY="2" as="b">
                  {pendingMembershipFees}
                </Text>{' '}
                cuotas sociales pendientes de pago. Pero no te preocupes: podés
                regularizar tu situación fácil y rápidamente. Hacé click aquí para más detalles:
              </Text>
            ) : (
              <Text paddingY="2">¡Felicitaciones! ¡Estás al día!</Text>
            )}
            <Button marginTop={2} variant="solid" colorScheme="green" onClick={handleMembershipFeesButton}>
              Ver mis cuotas sociales
            </Button>
            <Text paddingY="4" fontSize="sm">
              Recordá que el pago de las cuotas sociales constituye uno de los
              principales ingresos para mantener a nuestro club en pie.
            </Text>
          </CardBody>
        </Stack>
      </Card>
    );
  };

  const clubImportantAnnouncement = (): JSX.Element => {
    return (
      <Card direction="row" overflow="hidden" variant="filled" backgroundColor="gray.300" width={['100%', '100%', '50%']} alignSelf="start" >
        <Stack>
          <CardBody>
            <Heading size="md">Avisos</Heading>
            <Text paddingY="2">
              No hay avisos.
            </Text>
          </CardBody>
        </Stack>
      </Card>
    );
  };

  const getMemberActivities = () => {
    return member?.activities.map((activity) => {
      if (!activity.isInsurance) {
        return <MemberActivityDetails activity={activity}/>
      }
      return null;
    });
  }

  const memberActivities = (): JSX.Element => {
    return (
      <Card direction="row" overflow="hidden" variant="filled" backgroundColor="gray.300" width={['100%', '100%', '50%']} alignSelf="start">
        <Stack>
          <CardBody>
            <Heading size="md">Actividades</Heading>
            <>
              <Text paddingY="2">
                Estás dado de alta en las siguientes actividades:
              </Text>
              { getMemberActivities() }
            </>
          </CardBody>
        </Stack>
      </Card>
    );
  };

  const getMobileDisposition = () => {
    return (
      <VStack width="100%">
        {clubImportantAnnouncement()}
        {membershipFeesStatusCard()}
        {memberActivities()}
      </VStack>
    )
  };

  const getDesktopDisposition = () => {
    return (
      <>
        <HStack width="100%">
          {membershipFeesStatusCard()}
          {clubImportantAnnouncement()}
        </HStack>
        <HStack width="100%">
          {memberActivities()}
        </HStack>
      </>
    )
  };

  return (
    <Center width="100%">
      <VStack width={['90%', '90%', '100%']} align="start" >
        <Text>
          ¡Hola de nuevo, <Text as="b">{username}</Text>!
        </Text>
        { isMobile ? getMobileDisposition() : getDesktopDisposition() }
      </VStack>
    </Center>
  );
}

export default Dashboard;
