import type { Contact } from './address';
import { Activity } from './activity';
import { LookupListValue } from "./lookup-list-value";

export interface Member {
  id: string;
  firstName: string;
  lastName: string;
  documentNumber: number;
  birthdate: Date;
  gender: LookupListValue;
  memberNumber: number;
  membershipDate: Date;
  status: LookupListValue;
  memberType: LookupListValue;
  contact: Contact;
  activities: Activity[];
  owedMembershipFeesCount: number;
  imagePath?: string;
}

export interface MemberBasic {
  id: string;
  firstName: string;
  lastName: string;
  documentNumber: number;
  memberNumber: number;
  membershipDate: Date;
  type: string;
  imagePath?: string;
}

export const getMemberFullName = (member: Member | MemberBasic): string => {
  if (!member) return '';

  return `${member.firstName} ${member.lastName}`
}
