import React from "react";
import {Alert, AlertDescription, AlertIcon, AlertTitle, Box } from "@chakra-ui/react";

interface Props {
  status: "info" | "warning" | "success" | "error" | "loading" | undefined;
  title?: string;
  description?: string;
  hidden: boolean;
  additionalDescriptionElement?: any;
}

export default function AlertMessage(props: Props) {
  return (
    <Alert status={props.status} hidden={props.hidden}>
      <AlertIcon />
      <Box>
        <AlertTitle>{props.title}</AlertTitle>
        <AlertDescription>{props.description}</AlertDescription>
        {props.additionalDescriptionElement}
      </Box>
    </Alert>
  )
}
