import { extendTheme } from '@chakra-ui/react';

const theme = extendTheme({
  styles: {
    global: {
      'input, *::before, ::after': {
        borderColor: 'gray.500 !important'
      },
    },
  },
})

export default theme;
