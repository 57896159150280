import React from 'react';
import {
  Table,
  TableContainer,
  Tbody,
  Td,
  Tfoot,
  Th,
  Thead,
  Tr
} from '@chakra-ui/react';
import type { ProductToPay } from '../models/product-to-pay';
import { isMobile } from 'react-device-detect';
import { getShortMembershipFeeName } from '../utils/common';
import CurrencyData from "./currency-data";

interface Props {
  membershipFeesToPay: ProductToPay[];
  tableSize: string;
  setTotal?: (total: number) => void;
}

function ProductsToPayList(props: Props): JSX.Element {
  const getMembershipFeesToPay = (): JSX.Element | JSX.Element[] => {
    if (props.membershipFeesToPay.length === 0) {
      return <></>;
    }

    return props.membershipFeesToPay.map((membershipFeeToPay, index) => (
      <Tr key={index}>
        <Td>{isMobile ? membershipFeeToPay.shortName : membershipFeeToPay.name}</Td>
        <Td isNumeric><CurrencyData value={membershipFeeToPay.periodicProductPayment?.product.price ?? membershipFeeToPay.price}/></Td>
        <Td isNumeric><CurrencyData value={membershipFeeToPay.periodicProductPayment?.remainingPriceToPay ?? membershipFeeToPay.price}/></Td>
      </Tr>
    ));
  };

  const getTotal = (): number => {
    let total = 0;
    if (props.membershipFeesToPay.length === 0) {
      return total;
    }

    for (const membershipFeeToPay of props.membershipFeesToPay) {
      total += Number(membershipFeeToPay.periodicProductPayment?.remainingPriceToPay ?? membershipFeeToPay.price);
    }

    if (props.setTotal) {
      props.setTotal(total);
    }
    return total;
  };

  return (
    <TableContainer overflowX="-moz-hidden-unscrollable">
      <Table size={props.tableSize} width="100%" scrollBehavior="smooth">
        <Thead>
          <Tr>
            <Th textAlign="center">Detalle</Th>
            <Th textAlign="center">Valor original</Th>
            <Th textAlign="center">Valor final</Th>
          </Tr>
        </Thead>
        <Tbody>{getMembershipFeesToPay()}</Tbody>
        <Tfoot>
          <Tr>
            <Th></Th>
            <Th textAlign="end">TOTAL</Th>
            <Th isNumeric>
              <CurrencyData value={getTotal()}/>
            </Th>
          </Tr>
        </Tfoot>
      </Table>
    </TableContainer>
  );
}

export default ProductsToPayList;
