import { AxiosError, AxiosResponse } from 'axios';
import {
  FIND_MEMBER_DATA_ENDPOINT,
  GET_MEMBER_DATA_ENDPOINT,
  HTTP_STATUS_CODE_OK,
  PATCH_PARTNER_DATA_ENDPOINT_V2
} from '../constants';
import { Member } from '../models/member';
import axiosPrivate from '../models/axios/axios-private';
import { getEndpointResponse } from '../utils/endpoint-response';
import { HttpRequestResponse } from '../models/web/http-request-response';

export const updateMemberData = async (
  memberId: string,
  member: Member
): Promise<AxiosResponse | null> => {
  const url = PATCH_PARTNER_DATA_ENDPOINT_V2.replace('{MemberId}', memberId);
  const response = await axiosPrivate.patchForm(url, {
    data: JSON.stringify(member),
  });
  if (response.status === HTTP_STATUS_CODE_OK) {
    return response;
  } else {
    console.log(
      `Error while trying to update MemberId '${memberId}': ${response.statusText}`
    );
    return null;
  }
};

export const getMember = async (memberId: string): Promise<Member | null> => {
  const url = GET_MEMBER_DATA_ENDPOINT.replace('{MemberId}', memberId);
  const response = await axiosPrivate.get(url);
  if (response.status === HTTP_STATUS_CODE_OK) {
    return response.data;
  } else {
    console.log(
      `Error while trying to get MemberId '${memberId}': ${response.statusText}`
    );
    return null;
  }
};

export interface KeyValueParameter { parameterName: string, parameterData: string }

export const findMember = async (parameter: KeyValueParameter): Promise<HttpRequestResponse<Member>> => {
  const params = new URLSearchParams([[parameter.parameterName, parameter.parameterData]]);
  const response: AxiosResponse | AxiosError = await axiosPrivate
    .get<Member>(FIND_MEMBER_DATA_ENDPOINT, { params })
    .catch((err: AxiosError): AxiosError => err);

  return getEndpointResponse(response);
}
