import React, { useState } from 'react';
import { MemberBasic } from '../models/member';
import { Center } from '@chakra-ui/react';
import { useAuth } from '../components/auth';
import Credential from "../components/credential";

function MyMembershipCredential(): JSX.Element {
  const auth = useAuth();
  const [member] = useState(auth?.user?.selectedMember as MemberBasic);
  if (member == null) {
    return <></>;
  }

  return (
    <Center width="100%" marginTop="5%">
      <Credential member={member}/>
    </Center>
  );
}

export default MyMembershipCredential;
