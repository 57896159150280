import React from 'react';
import {
  Button,
  useDisclosure,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton
} from '@chakra-ui/react';

interface Props {
  dialogHeader: string;
  dialogBody: string;
  buttonLabel: string;
  submitAction: any;
}

function ConfirmationDialog(props: Props): JSX.Element {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef: React.MutableRefObject<any> = React.useRef();

  const handleConfirmation = (): void => {
    const submitAction = async (): Promise<void> => {
      (await props.submitAction)();
    };

    submitAction().then(
      () => onClose(),
      () => {}
    );
  };

  return (
    <>
      <Button onClick={onOpen} colorScheme="blue">
        {props.buttonLabel}
      </Button>
      <AlertDialog
        motionPreset="slideInBottom"
        leastDestructiveRef={cancelRef}
        onClose={onClose}
        isOpen={isOpen}
        isCentered
      >
        <AlertDialogOverlay />

        <AlertDialogContent>
          <AlertDialogHeader>{props.dialogHeader}</AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody>{props.dialogBody}</AlertDialogBody>
          <AlertDialogFooter>
            <Button colorScheme="blue" onClick={onClose} ref={cancelRef}>
              No
            </Button>
            <Button colorScheme="blue" onClick={handleConfirmation} ml={3}>
              Sí
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
}

export default ConfirmationDialog;
