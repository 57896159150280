import React, {ChangeEvent, useState} from "react";
import {Button, Input, Text, useToast, VStack} from "@chakra-ui/react";
import {makePostPaymentsCall, makePutPaymentsUploadReceiptCall} from "../services/payment-service";
import {ProductToPay} from "../models/product-to-pay";
import {PaymentDetail} from "../models/payment-detail";
import {PostPaymentRequest} from "../models/web/post-payment-request";
import {useNavigate} from "react-router-dom";
import AlertMessage from "./alert-message";
import CurrencyData from "./currency-data";
import {MY_MEMBERSHIP_FEES_PAGE_ROUTE, PAYMENT_METHOD_ID_BANK_TRANSFER} from "../constants";

interface Props {
  productsToPay: ProductToPay[];
  memberId: string;
  total: number;
}

export default function BankTransfer(props: Props) {
  const [file, setFile] = useState<File>();
  const toast = useToast();
  const navigate = useNavigate();
  const [showError, setShowError] = useState(false);

  const getPostPaymentsRequestBody = (): PostPaymentRequest => {
    const paymentDetails = props.productsToPay.map((ptp: ProductToPay): PaymentDetail => ({
      productId: ptp.productId,
      price: ptp.periodicProductPayment?.remainingPriceToPay ?? ptp.price,
      discriminator: ptp.periodicProductPayment?.product.discriminator
    }));
    return {
      memberId: props.memberId,
      paymentDetails: paymentDetails,
      methodId: PAYMENT_METHOD_ID_BANK_TRANSFER,
      comments: 'Pago por transferencia bancaria',
    };
  }

  const handleUploadButton = async() => {
    const postPaymentResponse = await makePostPaymentsCall(getPostPaymentsRequestBody());
    if (postPaymentResponse.statusCode === 201 && postPaymentResponse.response && file) {
      const uploadResponse = await makePutPaymentsUploadReceiptCall(postPaymentResponse.response.id, file);
      if (uploadResponse.statusCode === 201) {
        toast({
          title: '¡Genial!',
          description: 'El comprobante fue cargado correctamente.',
          status: 'success',
          duration: 9000,
          isClosable: true,
          position: 'bottom-right'
        });
        navigate(MY_MEMBERSHIP_FEES_PAGE_ROUTE);
      }
      else {
        setShowError(true);
      }
    }
  }

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setFile(e.target.files[0]);
    }
  }

  return (
    <VStack>
      <Text>Realizá la transferencia del importe correspondiente (<CurrencyData value={props.total}/>) a través de tu banco y luego cargá el comprobante de transferencia. El documento cargado será verificado manualmente y, posteriormente, se registrará el pago en el sistema.</Text>
      <Input variant="outline" type="file" onChange={handleFileChange} />
      <Button colorScheme="blue" disabled={!file} onClick={handleUploadButton}>Cargar comprobante</Button>
      <AlertMessage status="error" title="¡Uy! Algo salió mal" description="El documento no pudo ser cargado. Intentalo de nuevo." hidden={!showError}/>
    </VStack>
  )
}
