import {PeriodicProduct} from "../models/periodic-product";
import { PeriodicProductPayment } from "../models/periodic-product-payment";
import {ProductToPay} from "../models/product-to-pay";

export const getShortMembershipFeeName = (description: string): string => {
  const shortName = description.substring(13); // 'Cuota social '
  const firstLetter = shortName.charAt(0);
  return `${firstLetter.toUpperCase()}${shortName.slice(1)}`;
};

export const getProductToPayFromPeriodicProductPayment = (periodicProductPayment: PeriodicProductPayment): ProductToPay => {
  return {
    productId: periodicProductPayment.product.id!,
    name: periodicProductPayment.product.name,
    description: periodicProductPayment.product.description,
    price: periodicProductPayment.product.price,
    periodicProductPayment: periodicProductPayment
  };
}

export const getProductToPayFromPeriodicProduct = (periodicProduct: PeriodicProduct): ProductToPay => {
  return {
    productId: periodicProduct.id!,
    name: periodicProduct.name,
    description: periodicProduct.description,
    price: periodicProduct.price
  };
}
