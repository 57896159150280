import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
  Button,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  GridItem,
  Heading,
  Input, InputGroup, InputRightElement,
  Link,
  SimpleGrid,
  Text, useToast,
  VStack
} from '@chakra-ui/react';
import { LINK_COLOR, LOGIN_PAGE_ROUTE } from '../constants';
import { register } from '../services/user-service';
import { HttpStatusCode } from '../models/http-status-code';

function Registration(): JSX.Element {
  const [result, setResult] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfirmation, setShowPasswordConfirmation] = useState(false);
  const navigate = useNavigate();
  const toast = useToast();
  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
      passwordConfirmation: ''
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email(
          'Mmm... Esto no parece una dirección de correo electrónico válida.'
        )
        .required('Necesitamos saber tu dirección de correo electrónico.'),
      password: Yup.string()
        .required('Tenés que definir una contraseña segura para tu cuenta.')
        .min(5, 'La contraseña debe tener al menos 5 caracteres.')
        .max(255, 'La contraseña no puede tener más de 255 caracteres.'),
      passwordConfirmation: Yup.string()
        .required('Tenés que definir una contraseña segura para tu cuenta.')
        .min(5, 'La contraseña debe tener al menos 5 caracteres.')
        .max(255, 'La contraseña no puede tener más de 255 caracteres.')
        .test(
          'password-confirmation',
          'Las contraseñas no coinciden.',
          (value, context) => value === context.parent.password
        )
    }),
    onSubmit: async (values) => {
      const response = await register(values.email, values.password);

      switch (response.statusCode) {
        case HttpStatusCode.Created: {
          toast({
            id: 'register-response-toast',
            title: '¡Todo de diez! 👌🏻',
            description: 'El registro fue exitoso ✅ Ya podés iniciar sesión usando el correo electrónico y contraseña que ingresaste antes.',
            status: 'success',
            duration: 5000,
            isClosable: true,
            position: 'bottom-right'
          });
          break;
        }
        case HttpStatusCode.BadRequest: {
          setResult(response.message === 'Email account is already in use'
            ? 'El correo electrónico ingresado ya está en uso.'
            : '¡Uh! Algo salió mal. Intentalo de nuevo más tarde...');
          break;
        }
        default: {
          setResult('Algo no anda bien pero seguramente lo vamos a solucionar pronto. Intentalo de nuevo más tarde...');
          break;
        }
      }

      if (response.statusCode === HttpStatusCode.Created) {
        navigate(LOGIN_PAGE_ROUTE);
      }
    }
  });

  const handleShowPasswordClick = (): void => setShowPassword(!showPassword);
  const handleShowPasswordConfirmationClick = (): void => setShowPasswordConfirmation(!showPasswordConfirmation);

  return (
    <form onSubmit={formik.handleSubmit}>
      <VStack width="full" spacing={3}>
        <VStack
          width={['full', 'full', '40%']}
          alignItems="flex-start"
          spacing={3}
        >
          <Heading>Registro</Heading>
          <Text>
            Si ya tenés una cuenta, podés iniciar sesión haciendo click{' '}
            <Link color={LINK_COLOR} href={LOGIN_PAGE_ROUTE}>
              acá
            </Link>
            .
          </Text>
        </VStack>
        <VStack width={['full', 'full', '40%']}>
          <SimpleGrid width="full" spacing={3}>
            <GridItem>
              <FormControl
                isInvalid={
                  (formik.touched.email ?? false) && formik.errors.email != null
                }
              >
                <FormLabel htmlFor="email">Correo electrónico</FormLabel>
                <Input
                  id="email"
                  name="email"
                  type="email"
                  value={formik.values.email}
                  placeholder="juan.perez@gmail.com"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                <FormHelperText>
                  Quedate tranquilo: nunca vamos a compartir tu correo
                  electrónico con terceros. Tampoco vamos a enviarte demasiados
                  emails. Podrás desactivar las notificaciones una vez
                  registrado/a.
                </FormHelperText>
                <FormErrorMessage>{formik.errors.email}</FormErrorMessage>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl
                isInvalid={
                  (formik.touched.password ?? false) &&
                  formik.errors.password != null
                }
              >
                <FormLabel>Contraseña</FormLabel>
                <InputGroup>
                  <Input
                    id="password"
                    name="password"
                    type={showPassword ? 'text' : 'password'}
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  <InputRightElement width={['30%', '30%', '15%']}>
                    <Button height="1.75rem" size="sm" backgroundColor="blackAlpha.200" onClick={handleShowPasswordClick}>
                      {showPassword ? 'Ocultar' : 'Mostrar'}
                    </Button>
                  </InputRightElement>
                </InputGroup>
                <FormErrorMessage>{formik.errors.password}</FormErrorMessage>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl
                isInvalid={
                  (formik.touched.passwordConfirmation ?? false) &&
                  formik.errors.passwordConfirmation != null
                }
              >
                <FormLabel>Repetir contraseña</FormLabel>
                <InputGroup>
                  <Input
                    id="password-confirmation"
                    name="passwordConfirmation"
                    type={showPasswordConfirmation ? 'text' : 'password'}
                    value={formik.values.passwordConfirmation}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  <InputRightElement width={['30%', '30%', '15%']}>
                    <Button height="1.75rem" size="sm" backgroundColor="blackAlpha.200" onClick={handleShowPasswordConfirmationClick}>
                      {showPasswordConfirmation ? 'Ocultar' : 'Mostrar'}
                    </Button>
                  </InputRightElement>
                </InputGroup>
                <FormErrorMessage>
                  {formik.errors.passwordConfirmation}
                </FormErrorMessage>
              </FormControl>
            </GridItem>
            <GridItem marginTop={5}>
              <Button type="submit" width="full" colorScheme="blue">
                Registrarse
              </Button>
            </GridItem>
            <Text color="red">{result}</Text>
          </SimpleGrid>
        </VStack>
      </VStack>
    </form>
  );
}

export default Registration;
