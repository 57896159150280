import { Box, Heading, Text, VStack } from '@chakra-ui/react';
import React, {useState} from 'react';
import MercadoPago from '../components/mercado-pago';
import { useLocation } from 'react-router-dom';
import ProductsToPayList from '../components/products-to-pay-list';
import { isMobile } from 'react-device-detect';
import { Member } from '../models/member';
import { useAuth } from '../components/auth';
import { ProductToPay } from "../models/product-to-pay";
import BankTransfer from "../components/bank-transfer";

function PaymentPage(): JSX.Element {
  const auth = useAuth();
  const member = auth.user.selectedMember as Member;
  const { state } = useLocation();
  const paymentMethod = state.paymentMethod as string;
  const productsToPay = state.productsToPay as ProductToPay[];
  const [total, setTotal] = useState<number>(0);

  if (member == null || productsToPay.length === 0) {
    return <></>;
  }

  return (
    <VStack
      width={['full', 500, 'full']}
      align="center"
      spacing={3}
      padding={[3, 3, 10]}
      paddingTop={10}
    >
      <VStack
        width={['full', 'full', '80%']}
        align="flex-start"
        spacing={[5, 5, 3]}
      >
        <Heading>Confirmación del pago</Heading>
        <Text>
          Por favor, revisá atentamente el detalle de lo que vas a abonar a
          través de {state.paymentMethod === 'BankTransfer' ? 'transferencia bancaria' : state.paymentMethod}:
        </Text>
        <ProductsToPayList
          membershipFeesToPay={productsToPay}
          tableSize={isMobile ? 'sm' : 'md'}
          setTotal={setTotal}
        />
        <Box marginTop={5}>
          {
            paymentMethod === 'MercadoPago'
              ? (
                <MercadoPago
                  memberId={member.id}
                  memberNumber={member.memberNumber}
                  productsToPay={productsToPay}
                />
              )
              : <BankTransfer total={total} productsToPay={productsToPay} memberId={member.id}/>
          }
        </Box>
      </VStack>
    </VStack>
  );
}

export default PaymentPage;
