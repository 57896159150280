import React, { useEffect, useState } from 'react';
import {
  Badge,
  Center,
  Checkbox,
  Divider,
  Heading,
  Stack,
  Text,
  VStack
} from '@chakra-ui/react';
import { ProductToPay } from '../models/product-to-pay';
import { Card, CardFooter } from '@chakra-ui/card';
import { isMobile } from 'react-device-detect';
import {
  isInVerificationProcess,
  PeriodicProductPayment,
  requiresShowingProductExtraCharge
} from "../models/periodic-product-payment";
import CurrencyData from "./currency-data";
import {PAYMENT_VALIDATION_STATUS_ERROR, PAYMENT_VALIDATION_STATUS_PENDING} from "../constants";
import {badgeColor, getBadgeLabel, paymentCheckboxIsDisabled} from "../helpers";

interface Props {
  membershipFee: PeriodicProductPayment;
  toPayList: ProductToPay[];
  isFirstPayable: boolean;
  onChange: any;
}

function MembershipFeeCard(props: Props): JSX.Element {
  const date =
    props.membershipFee.payments != null && props.membershipFee.payments.length > 0
      ? new Date(props.membershipFee.payments.at(props.membershipFee.payments.length-1)?.date ?? Date.now())
          .toLocaleDateString('es-AR')
          .split('T')[0]
      : new Date().toISOString();
  const [isChecked, setIsChecked] = useState(false);

  useEffect(() => {
    if (
      props.toPayList.find(
        (membershipFee) =>
          membershipFee.productId ===
          props.membershipFee.product.id
      ) != null
    ) {
      setIsChecked(true);
    } else {
      setIsChecked(false);
    }
  }, [props.membershipFee.product.id, props.toPayList]);

  const handleChange = (): void => {
    props.onChange(props.membershipFee);
  };

  const cardEnabled = (): boolean => {
    if (props.isFirstPayable) return true;
    if (props.toPayList.length === 0) return false;

    const currentStartDate = new Date(props.membershipFee.product.startDate);
    const previousEndDate = new Date(currentStartDate.setUTCDate(currentStartDate.getUTCDate() - 2));
    return props.toPayList.findIndex(mf => new Date(mf.periodicProductPayment?.product.endDate!) >= previousEndDate &&
      new Date(mf.periodicProductPayment?.product.endDate!).getUTCMonth() <= previousEndDate.getUTCMonth()) > -1;
  }

  const getPayCheckbox = (membershipFee: PeriodicProductPayment) => {
    if (membershipFee.isCompleted) return (
        <Text width="100%" fontSize="sm" as="i" align="center">
          Fecha de pago: {date}
        </Text>
    )
    else {
      if (membershipFee.isApplicable) return (
        <Checkbox
          width="100%"
          isChecked={isChecked}
          onChange={handleChange}
          disabled={paymentCheckboxIsDisabled(membershipFee)}
        >
          Abonar
        </Checkbox>
      )
      else return (
        <Text width="100%" fontSize="sm" as="i" align="center">
          No aplicable
        </Text>
      )
    }
  }

  return (
    <Card width="100%">
      <Stack margin="2" spacing="2" minHeight="125px">
        <Heading size="md" alignSelf="center">
          {props.membershipFee.product.name}
        </Heading>
        <Divider />
        <Text minHeight="50px" wordBreak="break-word">
          {
            isMobile
              ? props.membershipFee.product.shortName
              : props.membershipFee.product.description
          }
        </Text>
        <Text color="blue.600" fontSize={['xl', 'lg', '2xl']}>
          <CurrencyData value={props.membershipFee.product.price}/>
        </Text>
        {
          requiresShowingProductExtraCharge(props.membershipFee)
            ? <Text>Valor con recargo: <CurrencyData value={props.membershipFee.totalPriceToPay}/></Text>
            : <></>
        }
      </Stack>
      <Divider />
      <CardFooter alignSelf="center">
        <Center>
          <VStack>
            <Badge
              width="fit-content"
              colorScheme={badgeColor(props.membershipFee)}
            >
              {getBadgeLabel(props.membershipFee)}
            </Badge>
            {getPayCheckbox(props.membershipFee)}
          </VStack>
        </Center>
      </CardFooter>
    </Card>
  );
}

export default MembershipFeeCard;
