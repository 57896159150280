import React, { useEffect, useState } from 'react';
import {
  IconButton,
  Avatar,
  Box,
  CloseButton,
  Flex,
  HStack,
  VStack,
  Icon,
  useColorModeValue,
  Link,
  Drawer,
  DrawerContent,
  Text,
  useDisclosure,
  BoxProps,
  FlexProps,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList, Img, Center, Divider
} from "@chakra-ui/react";
import { FiHome, FiMenu, FiBell, FiChevronDown, FiLogIn } from 'react-icons/fi';
import { IconType } from 'react-icons';
import { CgUserList } from 'react-icons/cg';
import { MdAppRegistration, MdOutlineCardMembership, MdSportsSoccer } from 'react-icons/md';
import { FaRegIdCard } from 'react-icons/fa';
import { useAuth } from './auth';
import { getUserFullName, Role, User } from '../models/user';
import { TbFileSymlink } from 'react-icons/tb';
import { Auth } from '../models/auth';
import { Link as ReactRouterLink, useNavigate } from 'react-router-dom';
import {
  LINK_MEMBER_PAGE_ROUTE,
  LOGIN_PAGE_ROUTE, MY_ACTIVITIES_PAGE,
  MY_DATA_PAGE_ROUTE,
  MY_MEMBERSHIP_CREDENTIAL_PAGE_ROUTE,
  MY_MEMBERSHIP_FEES_PAGE_ROUTE,
  REGISTRATION_PAGE_ROUTE
} from '../constants';
import {getMemberFullName} from "../models/member";

interface SidebarHeaderProps {
  content: JSX.Element;
}

interface LinkItemProps {
  name: string;
  icon: IconType;
  link: string;
  isPrivate: boolean;
  isPublicOnly: boolean;
  isPartnerOnly: boolean;
  isAnyUser: boolean;
  isAdminOnly: boolean;
}

interface NavItemProps extends FlexProps {
  label: string;
  icon: IconType;
  link: string;
}

interface SidebarProps extends BoxProps {
  onClose: () => void;
  isOpen: boolean;
}

const LinkItems: LinkItemProps[] = [
  {
    name: 'Iniciar sesión',
    icon: FiLogIn,
    link: LOGIN_PAGE_ROUTE,
    isPrivate: false,
    isPublicOnly: true,
    isAnyUser: false,
    isPartnerOnly: false,
    isAdminOnly: false
  },
  {
    name: 'Registro',
    icon: MdAppRegistration,
    link: REGISTRATION_PAGE_ROUTE,
    isPrivate: false,
    isPublicOnly: true,
    isAnyUser: false,
    isPartnerOnly: false,
    isAdminOnly: false
  },
  {
    name: 'Inicio',
    icon: FiHome,
    link: '/dashboard',
    isPrivate: true,
    isPublicOnly: false,
    isAnyUser: true,
    isPartnerOnly: false,
    isAdminOnly: false
  },
  {
    name: 'Enlazar socio',
    icon: TbFileSymlink,
    link: LINK_MEMBER_PAGE_ROUTE,
    isPrivate: true,
    isPublicOnly: false,
    isAnyUser: false,
    isPartnerOnly: false,
    isAdminOnly: false
  },
  {
    name: 'Mis datos',
    icon: CgUserList,
    link: MY_DATA_PAGE_ROUTE,
    isPrivate: true,
    isPublicOnly: false,
    isAnyUser: false,
    isPartnerOnly: true,
    isAdminOnly: false
  },
  {
    name: 'Mis cuotas',
    icon: MdOutlineCardMembership,
    link: MY_MEMBERSHIP_FEES_PAGE_ROUTE,
    isPrivate: true,
    isPublicOnly: false,
    isAnyUser: false,
    isPartnerOnly: true,
    isAdminOnly: false
  },
  {
    name: 'Mi credencial',
    icon: FaRegIdCard,
    link: MY_MEMBERSHIP_CREDENTIAL_PAGE_ROUTE,
    isPrivate: true,
    isPublicOnly: false,
    isAnyUser: false,
    isPartnerOnly: true,
    isAdminOnly: false
  },
  {
    name: 'Mis actividades',
    icon: MdSportsSoccer,
    link: MY_ACTIVITIES_PAGE,
    isPrivate: true,
    isPublicOnly: false,
    isAnyUser: false,
    isPartnerOnly: true,
    isAdminOnly: false
  }
];

function Sidebar(props: SidebarHeaderProps): JSX.Element {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <Box minH="100vh" bg={useColorModeValue('gray.100', 'gray.900')}>
      <SidebarContent
        onClose={() => onClose}
        background="linear-gradient(240deg, #176f1e 50%, #1c9e0cd4 100%);"
        display={{ base: 'none', md: 'block' }}
        fontSize="1.1em"
        isOpen={isOpen}
      />
      <Drawer
        autoFocus={false}
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        returnFocusOnClose={false}
        onOverlayClick={onClose}
        size="full"
      >
        <DrawerContent>
          <SidebarContent onClose={onClose} isOpen={isOpen} />
        </DrawerContent>
      </Drawer>
      {/* MobileNav */}
      <MobileNav onOpen={onOpen}/>
      <Box ml={{ base: 0, md: 60 }} padding={10}>
        {props.content}
      </Box>
    </Box>
  );
}

const SidebarContent = ({ onClose, isOpen, ...rest }: SidebarProps): JSX.Element => {
  const [links, setLinks] = useState<JSX.Element[]>([]);
  const authContext = useAuth();
  const user = authContext.user as User;

  useEffect(() => {
    const getLinks = (): JSX.Element[] => {
      return LinkItems.filter((link) => {
        if (
          ((!link.isPrivate && link.isPublicOnly && user == null) ?? false) ||
          ((link.isPrivate && link.isPartnerOnly && user?.selectedMember != null) ??
            false) ||
          ((link.isPrivate && link.isAnyUser && user != null) ?? false) ||
          ((link.isPrivate &&
            !link.isPartnerOnly &&
            user != null &&
            user?.selectedMember == null) ??
            false) ||
          (link.isAdminOnly && user?.role === Role.Admin)
        ) {
          return link;
        }
        return null;
      }).map((link) => {
        return (
          <NavItem fontWeight="semibold"
            key={link.link}
            label={link.name}
            icon={link.icon}
            link={link.link}
            onClick={() => {
              if (isOpen) {
                onClose();
              }
            }}
          >
            {link.name}
          </NavItem>
        );
      });
    };

    setLinks(getLinks());
  }, [user, user?.selectedMember]);

  return (
    <Box
      transition="3s ease"
      backgroundColor={useColorModeValue('white', 'gray.900')}
      borderRight="1px"
      borderRightColor={useColorModeValue('gray.200', 'gray.700')}
      width={{ base: 'full', md: 60 }}
      position="fixed"
      height="full"
      {...rest}
    >
      <Flex
        height="20"
        alignItems="center"
        mx="8"
        justifyContent="space-between"
      >
        <Img boxSize='80px' objectFit='cover' src={'/pacifico.png'}></Img>
        <Text fontSize="2xl" fontFamily="Verdana" fontWeight="bold">C.A. Pacífico</Text>
        <CloseButton display={{ base: 'flex', md: 'none' }} onClick={onClose} />
      </Flex>
      <Center><Text fontSize="lg" fontFamily="sloganFont">El primero de la ciudad</Text></Center>
      <Divider />
      {links}
    </Box>
  );
};

const NavItem = ({
  label,
  icon,
  link,
  children,
  ...rest
}: NavItemProps): JSX.Element => {
  return (
    <Link
      as={ReactRouterLink}
      to={link}
      style={{ textDecoration: 'none' }}
      _focus={{ boxShadow: 'none' }}
    >
      <Flex
        align="center"
        p="4"
        mx="4"
        borderRadius="lg"
        role="group"
        cursor="pointer"
        _hover={{
          bg: '#81b47d',
          color: 'white'
        }}
        {...rest}
      >
        {icon != null && (
          <Icon
            mr="4"
            fontSize="16"
            _groupHover={{
              color: 'white'
            }}
            as={icon}
          />
        )}
        {label}
      </Flex>
    </Link>
  );
};

interface MobileProps extends FlexProps {
  onOpen: () => void;
}

const MobileNav = ({ onOpen, ...rest }: MobileProps): JSX.Element => {
  const authContext: Auth = useAuth();
  const navigate = useNavigate();
  // background="linear-gradient(240deg, #176f1e 50%, #1c9e0cd4 100%);"
  const background = useColorModeValue('white', 'gray.900');
  const borderBottom = useColorModeValue('gray.200', 'gray.700');
  const menuList = useColorModeValue('white', 'gray.900');
  const menuListBorder = useColorModeValue('gray.200', 'gray.700');
  const user = authContext.user as User;

  if (user == null) {
    return <></>;
  }

  const username = getUserFullName(user);

  const handleLogout = (): void => {
    const signout = async (): Promise<void> => {
      await authContext.logout();
      navigate('/login');
    };

    signout().catch(() => {});
  };

  return (
    <Flex
      ml={{ base: 0, md: 60 }}
      px={{ base: 4, md: 4 }}
      height="20"
      alignItems="center"
      bg={background}
      borderBottomWidth="1px"
      borderBottomColor={borderBottom}
      justifyContent={{ base: 'space-between', md: 'flex-end' }}
      {...rest}
    >
      <IconButton
        display={{ base: 'flex', md: 'none' }}
        onClick={onOpen}
        variant="outline"
        aria-label="open menu"
        icon={<FiMenu />}
      />

      <Text
        display={{ base: 'flex', md: 'none' }}
        fontSize="2xl"
        fontFamily="Verdana"
        fontWeight="bold"
      >
        C.A. Pacífico
      </Text>

      <HStack spacing={{ base: '0', md: '6' }}>
        <IconButton
          size="lg"
          variant="ghost"
          aria-label="open menu"
          icon={<FiBell />}
        />
        <Flex alignItems={'center'}>
          <Menu>
            <MenuButton
              py={2}
              transition="all 0.3s"
              _focus={{ boxShadow: 'none' }}
            >
              <HStack>
                <Avatar size={'md'} name={getUserFullName(user)} src={user.selectedMember?.imagePath ?? `/default-profile-picture.jpeg`} />
                <VStack
                  display={{ base: 'none', md: 'flex' }}
                  alignItems="flex-start"
                  spacing="1px"
                  ml="2"
                >
                  <Text fontSize="sm">{username}</Text>
                  <Text fontSize="xs" color="gray.600">
                    Socio N° {user.selectedMember?.memberNumber}
                  </Text>
                </VStack>
                <Box display={{ base: 'none', md: 'flex' }}>
                  <FiChevronDown />
                </Box>
              </HStack>
            </MenuButton>
              <MenuList bg={menuList} borderColor={menuListBorder}>
                {
                  user?.members.length > 1
                    ? (
                      user?.members.map((member) => (
                        <MenuItem key={member.id} minH='48px' isDisabled={user?.selectedMember?.id === member.id} onClick={() => {
                          authContext.linkMember(member);
                          navigate('/dashboard');
                        }}>
                          <Avatar size={'sm'} name={getMemberFullName(member)} src={member.imagePath ?? ''} />
                          <span>{getMemberFullName(member)}</span>
                        </MenuItem>
                      ))
                    )
                    : null
                }
                {
                  user?.members.length > 1
                    ? <MenuDivider />
                    : null
                }
                <MenuItem>
                  <Text onClick={() => { navigate(LINK_MEMBER_PAGE_ROUTE) }}>Enlazar otro socio</Text>
                </MenuItem>
                <MenuDivider />
                <MenuItem>
                  <Text onClick={handleLogout}>Cerrar sesión</Text>
                </MenuItem>
              </MenuList>
          </Menu>
        </Flex>
      </HStack>
    </Flex>
  );
};

export default Sidebar;
