import React, { useEffect, useState } from 'react';
import {
  Box,
  VStack,
  Text,
  Center,
  Heading,
  SimpleGrid,
  HStack
} from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import { Member } from '../models/member';
import { getMember } from '../services/member-service';
import { CheckIcon, CloseIcon } from '@chakra-ui/icons';
import MemberCredentialCard from '../components/member-credential-card';

function MemberStatus(): JSX.Element {
  const { id } = useParams();
  const [member, setMember] = useState<Member | null>(null);

  useEffect((): void => {
    const fetchData = async (): Promise<void> => {
      setMember(await getMember(id ?? ''));
    };

    fetchData().catch(() => {});
  }, [id]);

  return (
    <Center width="100%" marginTop="5%">
      <VStack width="100%">
        {member != null ? <MemberCredentialCard member={member} /> : <></>}
        <Box width="60%">
          <Heading>Estado del socio</Heading>
          <SimpleGrid
            marginTop={5}
            borderRadius={10}
            borderStyle="solid"
            borderWidth="1px"
            borderColor="blackAlpha.200"
            columns={2}
            spacing={4}
            alignItems="center"
          >
            <Box
              marginTop={3}
              paddingLeft={4}
              height="100%"
              fontSize="md"
              as="b"
            >
              Cuotas sociales
            </Box>
            <Box height="100%">
              {(member?.owedMembershipFeesCount ?? 0) >= 0 ? (
                <HStack>
                  <CloseIcon color="red.500" width={7} height={7} />
                  <Text>
                    Adeuda{' '}
                    <Text as="b" color="red.500">
                      {member?.owedMembershipFeesCount}
                    </Text>{' '}
                    cuotas sociales
                  </Text>
                </HStack>
              ) : (
                <HStack>
                  <CheckIcon color="green.500" width={7} height={7} />
                  <Text>Al día: no adeuda cuotas sociales</Text>
                </HStack>
              )}
            </Box>
            <Box
              marginTop={3}
              paddingLeft={4}
              height="100%"
              fontSize="md"
              as="b"
            >
              Pase a tribuna
            </Box>
            <Box height="100%">
              <HStack>
                <CheckIcon color="green.500" width={7} height={7} />
                <Text>Abonado</Text>
              </HStack>
            </Box>
            <Box
              marginTop={3}
              paddingLeft={4}
              height="100%"
              fontSize="md"
              as="b"
            >
              Platea anual
            </Box>
            <Box height="100%">
              <HStack>
                <CheckIcon color="green.500" width={7} height={7} />
                <Text>Posee abono de platea anual</Text>
              </HStack>
            </Box>
            <Box
              marginTop={3}
              paddingLeft={4}
              height="100%"
              fontSize="md"
              as="b"
            >
              Palco anual
            </Box>
            <Box height="100%">
              <HStack>
                <CheckIcon color="green.500" width={7} height={7} />
                <Text>Posee abono de palco anual</Text>
              </HStack>
            </Box>
          </SimpleGrid>
        </Box>
      </VStack>
    </Center>
  );
}

export default MemberStatus;
