import {
  Button,
  FormControl, FormErrorMessage,
  FormLabel,
  GridItem,
  Heading,
  Input, InputGroup, InputRightElement,
  SimpleGrid,
  Text, useToast,
  VStack
} from "@chakra-ui/react";
import React, {useState} from "react";
import {Form, Formik} from "formik";
import * as Yup from "yup";
import {reset} from "../services/user-service";
import {useNavigate, useSearchParams} from "react-router-dom";
import {
  HTTP_STATUS_CODE_NO_CONTENT,
  HTTP_STATUS_CODE_OK, LOGIN_PAGE_ROUTE,
  TOAST_ERROR,
  TOAST_ERROR_TITLE,
  TOAST_PASSWORD_RESET_ERROR, TOAST_PASSWORD_RESET_SUCCESS,
  TOAST_SUCCESS,
  TOAST_SUCCESS_TITLE
} from "../constants";
import {AlertStatus} from "@chakra-ui/alert";

function Reset() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [result, setResult] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfirmation, setShowPasswordConfirmation] = useState(false);
  const toast = useToast();
  const navigate = useNavigate();

  const handleShowPasswordClick = (): void => setShowPassword(!showPassword);
  const handleShowPasswordConfirmationClick = (): void => setShowPasswordConfirmation(!showPasswordConfirmation);

  return (
    <Formik
      initialValues={{
        password: '',
        passwordConfirmation: ''
      }}
      validationSchema={
        Yup.object({
          password: Yup.string()
            .required('Tenés que definir una contraseña segura para tu cuenta.')
            .min(5, 'La contraseña debe tener al menos 5 caracteres.')
            .max(255, 'La contraseña no puede tener más de 255 caracteres.'),
          passwordConfirmation: Yup.string()
            .required('Tenés que definir una contraseña segura para tu cuenta.')
            .min(5, 'La contraseña debe tener al menos 5 caracteres.')
            .max(255, 'La contraseña no puede tener más de 255 caracteres.')
            .test(
              'password-confirmation',
              'Las contraseñas no coinciden.',
              (value, context) => value === context.parent.password
            )
        })
      }
      onSubmit={(values, { setSubmitting }) => {
        let title = TOAST_ERROR_TITLE;
        let description = TOAST_PASSWORD_RESET_ERROR;
        let status: AlertStatus = TOAST_ERROR;

        setTimeout(async () => {
          const response = await reset(searchParams.get("token") as string, values.password);
          if (response?.statusCode === HTTP_STATUS_CODE_OK || response?.statusCode === HTTP_STATUS_CODE_NO_CONTENT) {
            title = TOAST_SUCCESS_TITLE;
            description = TOAST_PASSWORD_RESET_SUCCESS;
            status = TOAST_SUCCESS;
          }

          toast({
            title,
            description,
            status,
            duration: 9000,
            isClosable: true,
            position: 'bottom-right'
          });

          setSubmitting(false);
          navigate(LOGIN_PAGE_ROUTE, { replace: true });
        }, 400);
      }}
    >
      {
        formik => (
          <VStack width="full" spacing={3}>
            <VStack
              width={['full', 'full', '40%']}
              alignItems="flex-start"
              spacing={3}
            >
              <Heading>Restablecer contraseña</Heading>
              <Text>Con este proceso podrás restablecer la contraseña de la cuenta asociada a la dirección de correo electrónico que te trajo hasta acá.</Text>
              <Text>A continuación, podrás definir tu nueva contraseña.</Text>
            </VStack>
            <VStack width={['full', 'full', '40%']}>
              <Form style={{ width: '100%' }}>
                <SimpleGrid width="full" spacing={3}>
                  <GridItem>
                    <FormControl
                      isInvalid={
                        (formik.touched.password ?? false) &&
                        formik.errors.password != null
                      }
                    >
                      <FormLabel>Contraseña</FormLabel>
                      <InputGroup>
                        <Input
                          id="password"
                          name="password"
                          type={showPassword ? 'text' : 'password'}
                          value={formik.values.password}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                        <InputRightElement width={['30%', '30%', '15%']}>
                          <Button height="1.75rem" size="sm" backgroundColor="blackAlpha.200" onClick={handleShowPasswordClick}>
                            {showPassword ? 'Ocultar' : 'Mostrar'}
                          </Button>
                        </InputRightElement>
                      </InputGroup>
                      <FormErrorMessage>{formik.errors.password}</FormErrorMessage>
                    </FormControl>
                  </GridItem>
                  <GridItem>
                    <FormControl
                      isInvalid={
                        (formik.touched.passwordConfirmation ?? false) &&
                        formik.errors.passwordConfirmation != null
                      }
                    >
                      <FormLabel>Repetir contraseña</FormLabel>
                      <InputGroup>
                        <Input
                          id="password-confirmation"
                          name="passwordConfirmation"
                          type={showPasswordConfirmation ? 'text' : 'password'}
                          value={formik.values.passwordConfirmation}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                        <InputRightElement width={['30%', '30%', '15%']}>
                          <Button height="1.75rem" size="sm" backgroundColor="blackAlpha.200" onClick={handleShowPasswordConfirmationClick}>
                            {showPasswordConfirmation ? 'Ocultar' : 'Mostrar'}
                          </Button>
                        </InputRightElement>
                      </InputGroup>
                      <FormErrorMessage>
                        {formik.errors.passwordConfirmation}
                      </FormErrorMessage>
                    </FormControl>
                  </GridItem>
                  <GridItem marginTop={5}>
                    <Button type="submit" width="full" colorScheme="green">
                      Restablecer contraseña
                    </Button>
                  </GridItem>
                  <Text color="red">{result}</Text>
                </SimpleGrid>
              </Form>
            </VStack>
          </VStack>
        )
      }
    </Formik>
  )
}

export default Reset;
