import { Box, List, ListIcon, ListItem, Text } from '@chakra-ui/react';
import { BiTime } from 'react-icons/bi';
import { GrLocation } from 'react-icons/gr';
import { FaChalkboardTeacher, FaRegMoneyBillAlt } from 'react-icons/fa';
import * as React from 'react';
import { Activity } from '../models/activity';
import CurrencyData from "./currency-data";

interface Props {
  activity: Activity;
}

function MemberActivityDetails(props: Props): JSX.Element {
  return (
    <Box key={props.activity.id} alignSelf="flex-start">
      <Text as="b" fontSize="lg">{props.activity.name}</Text>
      <List spacing={ props.activity.isInsurance ? 0 : 1 }>
        {
          props.activity.isInsurance
            ? <></>
            : (
              <>
                <ListItem key={`${props.activity.id}scheduleDetails`}>
                  <ListIcon as={BiTime} color='black.500' />
                  Horario: {props.activity.scheduleDetails}
                </ListItem>
                <ListItem key={`${props.activity.id}location`}>
                  <ListIcon as={GrLocation} color='black.500' />
                Ubicación: {props.activity.location}
                </ListItem>
                  <ListItem key={`${props.activity.id}instructorsFullNames`}>
                <ListIcon as={FaChalkboardTeacher} color='black.500' />
                Instructores: {props.activity.responsiblePersonsFullNames}
                </ListItem>
              </>
            )
        }
        <ListItem key={`${props.activity.id}monthlyCost`}>
          <ListIcon as={FaRegMoneyBillAlt} color='black.500' />
            Valor mensual: <CurrencyData value={props.activity.monthlyPrice}/>
        </ListItem>
      </List>
    </Box>
  );
}

export default MemberActivityDetails;
