import { HttpRequestResponse } from '../models/web/http-request-response';
import axiosPrivate from '../models/axios/axios-private';
import {
  GET_ACTIVITIES_FEES_ENDPOINT,
  GET_ACTIVITIES_FEES_FOR_MEMBERSHIP_FEE_BY_MEMBER_ID_ENDPOINT,
  GET_MEMBERSHIP_FEES_ENDPOINT, GET_MEMBERSHIP_FEES_ENDPOINT_V2
} from "../constants";
import { AxiosError, AxiosResponse } from 'axios';
import { MembershipFeesResponse } from '../models/web/membership-fee-response';
import { ActivitiesFeesByMemberResponse } from "../models/activities-fees-by-member-response";
import {PeriodicProduct} from "../models/periodic-product";
import {PeriodicProductPayment} from "../models/periodic-product-payment";

export const getMembershipFeesByMember = async (memberId: string): Promise<HttpRequestResponse<MembershipFeesResponse>> => {
  const response: AxiosResponse = await axiosPrivate
    .get(GET_MEMBERSHIP_FEES_ENDPOINT_V2.replace('{MemberId}', memberId))
    .catch((err: AxiosError): AxiosResponse | any => err.response);

  return {
    message: response.data.message,
    statusCode: response.status,
    response: response.data
  }
}

export const getActivitiesFeesByMember = async (memberId: string): Promise<HttpRequestResponse<ActivitiesFeesByMemberResponse>> => {
  const response: AxiosResponse = await axiosPrivate
    .get(GET_ACTIVITIES_FEES_ENDPOINT.replace('{MemberId}', memberId))
    .catch((err: AxiosError): AxiosResponse | any => err.response);

  return {
    message: response.data.message,
    statusCode: response.status,
    response: response.data
  }
}

export const getActivitiesForMembershipFeeByMemberId = async (memberId: string, membershipFeeId: string): Promise<HttpRequestResponse<PeriodicProductPayment[]>> => {
  const response: AxiosResponse = await axiosPrivate
    .get(GET_ACTIVITIES_FEES_FOR_MEMBERSHIP_FEE_BY_MEMBER_ID_ENDPOINT
      .replace('{MemberId}', memberId)
      .replace('{MembershipFeeId}', membershipFeeId))
    .catch((err: AxiosError): AxiosResponse | any => err.response);

  return {
    message: response.data.message,
    statusCode: response.status,
    response: response.data
  }
}
