import { AxiosResponse } from 'axios';
import {
  MERCADO_PAGO_ENDPOINT,
  MY_MEMBERSHIP_FEES_PAGE_ROUTE,
  POST_PAYMENT_ENDPOINT, POST_PAYMENT_ENDPOINT_V2, PUT_PAYMENT_UPLOAD_RECEIPT_ENDPOINT
} from '../constants';
import type { MercadoPagoPreferenceResponse } from '../models/mercado-pago-preference-response';
import {
  MercadoPagoPaymentRequest, PaymentDetail,
  ProductsPayment
} from '../models/product-to-pay';
import type { MercadoPagoPreferenceItem } from '../models/mercado-pago-preference-item';
import type { MercadoPagoPreference } from '../models/mercado-pago-preference';
import axiosPrivate from '../models/axios/axios-private';
import {Payment} from "../models/payment";
import {HttpRequestResponse} from "../models/web/http-request-response";
import {AxiosError} from "axios";
import {getEndpointResponse} from "../utils/endpoint-response";
import {PostPaymentRequest} from "../models/web/post-payment-request";

export const makePostPaymentsCall = async(payment: PostPaymentRequest): Promise<HttpRequestResponse<Payment>> => {
  const response: AxiosResponse | AxiosError = await axiosPrivate
    .post<Payment>(POST_PAYMENT_ENDPOINT_V2, payment)
    .catch((err: AxiosError): AxiosError => err);

  return getEndpointResponse(response);
}

export const makePutPaymentsUploadReceiptCall = async(paymentId: string, file: File): Promise<HttpRequestResponse<Payment>> => {
  const response: AxiosResponse | AxiosError = await axiosPrivate
    .putForm<Payment>(PUT_PAYMENT_UPLOAD_RECEIPT_ENDPOINT.replace('{PaymentId}', paymentId), {
      receipt: file
    })
    .catch((err: AxiosError): AxiosError => err);

  return getEndpointResponse(response);
}

export const getMercadoPagoPreferenceId = async (
  mercadoPagoPaymentData: ProductsPayment
): Promise<string | null> => {
  if (mercadoPagoPaymentData.products.length === 0) {
    return null;
  }

  const preferenceItems: MercadoPagoPreferenceItem[] = [];
  for (const membershipFee of mercadoPagoPaymentData.products) {
    const preferenceItem: MercadoPagoPreferenceItem = {
      id: membershipFee.productId,
      title: `C.A. Pacífico - ${membershipFee.description}`,
      description: membershipFee.name,
      unit_price: Number(membershipFee.price),
      quantity: 1
    };
    preferenceItems.push(preferenceItem);
  }

  const preference: MercadoPagoPreference = {
    items: preferenceItems,
    back_urls: {
      success: `${window.location.origin}${MY_MEMBERSHIP_FEES_PAGE_ROUTE}`,
      pending: `${window.location.origin}${MY_MEMBERSHIP_FEES_PAGE_ROUTE}`
    }
  };

  const mercadoPagoPaymentRequest: MercadoPagoPaymentRequest = {
    payment: {
      memberId: mercadoPagoPaymentData.memberId,
      paymentDetails: mercadoPagoPaymentData.products.map(
        (p): PaymentDetail => {
          return {
            productId: p.productId,
            price: Number(p.price)
          };
        }
      ),
      methodId: 'ebc10387-d7d8-4518-97aa-2c14eed5d6d8',
      comments: 'MercadoPago'
    },
    mercadoPagoPreference: preference
  };

  return await axiosPrivate
    .post(MERCADO_PAGO_ENDPOINT, mercadoPagoPaymentRequest)
    .then(
      (response: AxiosResponse<MercadoPagoPreferenceResponse>) =>
        response.data.id
    );
};
