import * as React from 'react';
import * as ReactDOM from 'react-dom/client';
// import reportWebVitals from "./reportWebVitals";
import { ChakraProvider } from '@chakra-ui/react';
import AppRouter from './app-router';
import { AuthProvider } from './components/auth';
import theme from './styles';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <ChakraProvider theme={theme}>
      <AuthProvider>
        <AppRouter />
      </AuthProvider>
    </ChakraProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
