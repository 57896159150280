import React  from 'react';
import {Button, Center, HStack, Text, useToast, VStack} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from './auth';
import type { Member } from '../models/member';
import {
  PATCH_PARTNER_DATA_ENDPOINT_V2,
  SHORT_ERROR_DESCRIPTION, TOAST_ERROR,
  TOAST_ERROR_TITLE, TOAST_SUCCESS,
  TOAST_SUCCESS_TITLE
} from '../constants';
import { User } from '../models/user';
import axiosPrivate from '../models/axios/axios-private';
import {AlertStatus} from "@chakra-ui/alert";

interface Props {
  member: Member;
}

function PartnerDataBox(props: Props): JSX.Element {
  const auth = useAuth();
  const navigate = useNavigate();
  const toast = useToast();
  const user = auth.user as User;

  const submitForm = (event: { preventDefault: () => void }): void => {
    event.preventDefault();
    let title = TOAST_ERROR_TITLE;
    let description = `No pudimos enlazar tu cuenta al socio seleccionado. ${SHORT_ERROR_DESCRIPTION}`
    let status: AlertStatus = TOAST_ERROR;

    axiosPrivate
      .patchForm(PATCH_PARTNER_DATA_ENDPOINT_V2.replace('{MemberId}', props.member.id ?? ''), {
        data: JSON.stringify({
          id: props.member?.id,
          userId: user.id
        })
      })
      .then(
        (): void => {
          title = TOAST_SUCCESS_TITLE;
          description = 'El socio fue enlazado correctamente a la cuenta.';
          status = TOAST_SUCCESS;
          auth.linkMember(props.member);
          navigate('/dashboard', {
            state: { firstName: props.member.firstName }
          });
          toast({
            title,
            description,
            status,
            duration: 9000,
            isClosable: true,
            position: 'bottom-right'
          });
        })
      .catch(() => {});
  };

  return (
    <form onSubmit={submitForm}>
      <Center paddingTop={10}>
        <VStack width={['full', 'full', '40%']} spacing={3} align="flex-start">
          <Text fontSize="xl">¡Creo que pudimos encontrarte! 😎</Text>
            <HStack><Text as="b">Nombre:</Text><Text>{props.member?.firstName}</Text></HStack>
            <HStack><Text as="b">Apellido:</Text><Text>{props.member?.lastName}</Text></HStack>
            <HStack><Text as="b">Número de documento:</Text><Text>{props.member?.documentNumber}</Text></HStack>
            <HStack><Text as="b">Número de socio:</Text><Text>{props.member?.memberNumber}</Text></HStack>
          <Button
            width="full"
            alignSelf="center"
            type="submit"
            colorScheme="blue"
          >
            Enlazar cuenta
          </Button>
        </VStack>
      </Center>
    </form>
  );
}

export default PartnerDataBox;
