import React, { useState } from 'react';
import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  GridItem,
  Heading,
  HStack,
  Input,
  SimpleGrid,
  Text,
  VStack
} from '@chakra-ui/react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import PartnerDataBox from '../components/partner-data-box';
import RadioButtons from '../components/radio-group';
import { Member } from '../models/member';
import { isMobile } from 'react-device-detect';
import { findMember } from '../services/member-service';

const PENDING = 'Pending';
const IN_PROGRESS = 'In Progress';
const COMPLETE = 'Complete';
const NOT_FOUND = 'Not Found';
const FOUND = 'Found';

function FirstLogin(): JSX.Element {
  const [displayMembershipNumberSearch, setDisplayMembershipNumberSearch] =
    useState(true);
  const [displayDocumentNumberSearch, setDisplayDocumentNumberSearch] =
    useState(false);
  const [partnerData, setPartnerData] = useState(null as unknown as Member);
  const [queryStatus, setQueryStatus] = useState(PENDING);
  const [queryResult, setQueryResult] = useState('');

  const formik = useFormik({
    initialValues: {
      memberNumber: '',
      documentNumber: ''
    },
    validationSchema: Yup.object({
      memberNumber: Yup.number()
        .positive('El número de socio ingresado no es válido.')
        .integer('El número de socio ingresado no es válido.'),
      documentNumber: Yup.number()
        .positive('El número de documento ingresado no es válido.')
        .integer('El número de documento ingresado no es válido.')
    }),
    onSubmit: async (values) => {
      if (displayMembershipNumberSearch && values.memberNumber == null) {
        return;
      }
      if (displayDocumentNumberSearch && values.documentNumber == null) {
        return;
      }

      setQueryStatus(IN_PROGRESS);
      const dataNumber = displayMembershipNumberSearch
        ? values.memberNumber
        : values.documentNumber;
      const paramName = displayMembershipNumberSearch
        ? 'memberNumber'
        : 'documentNumber';

      const response = await findMember({ parameterName: paramName, parameterData: dataNumber });

      setQueryStatus(COMPLETE);
      switch (response.statusCode) {
        case 200:
          setQueryResult(FOUND);
          if (response.response !== null) {
            setPartnerData(response.response);
          }
          break;
        case 204:
          setQueryResult(NOT_FOUND);
          break;
        default:
          setQueryResult(NOT_FOUND);
          break;
      }
    }
  });

  const handleRadioButtonChange = (selectedValue: string): void => {
    switch (selectedValue) {
      case '1':
        setDisplayDocumentNumberSearch(false);
        setDisplayMembershipNumberSearch(true);
        break;
      case '2':
        setDisplayDocumentNumberSearch(true);
        setDisplayMembershipNumberSearch(false);
        break;
    }
  };

  const retrySearch = (): void => {
    setQueryStatus(PENDING);
    setQueryResult('');
  };

  const searchButtons = (): JSX.Element => {
    return (
      <>
        <Button
          type="submit"
          width={['full', 'full', '50%']}
          alignSelf="center"
          colorScheme="blue"
          isDisabled={queryStatus === COMPLETE || ((displayMembershipNumberSearch && !formik.values.memberNumber) || (displayDocumentNumberSearch && !formik.values.documentNumber))}
          marginLeft={[null, null, 0]}
          marginRight={[null, null, 3]}
        >
          Buscar socio
        </Button>
        <Button
          type="button"
          width={['full', 'full', '50%']}
          alignSelf="center"
          colorScheme="blue"
          isDisabled={queryStatus !== COMPLETE}
          marginLeft={[null, null, 3]}
          marginRight={[null, null, 0]}
          onClick={retrySearch}
        >
          Reintentar búsqueda
        </Button>
      </>
    );
  };

  return (
    <Box width="full" paddingBottom={3}>
      <form onSubmit={formik.handleSubmit}>
        <VStack width="full" spacing={5}>
          <VStack width={['full', 'full', '40%']} align="flex-start" spacing={5}>
            <Heading>¿Ya sos socio?</Heading>
            <Text>
              Si ya sos socio, necesitamos enlazar tu cuenta con tu número de
              socio. Para que lo podamos hacer, por favor brindanos tu número de
              socio o, en caso de que no lo tengas a mano, tu documento de
              identidad.
            </Text>
          </VStack>
          <VStack width={['full', 'full', '40%']} mt={5} spacing={5}>
            <RadioButtons
              callback={handleRadioButtonChange}
              isDisabled={queryStatus === COMPLETE}
            />
            <SimpleGrid width="full">
              {displayMembershipNumberSearch ? (
                <GridItem>
                  <FormControl
                    width="full"
                    isInvalid={
                      (formik.touched.memberNumber ?? false) &&
                      formik.errors.memberNumber != null
                    }
                  >
                    <FormLabel htmlFor="member-number">Número de socio</FormLabel>
                    <Input
                      id="member-number"
                      name="memberNumber"
                      type="number"
                      value={formik.values.memberNumber}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      isDisabled={queryStatus === COMPLETE}
                    />
                    <FormErrorMessage>
                      {formik.errors.memberNumber}
                    </FormErrorMessage>
                  </FormControl>
                </GridItem>
              ) : (
                ''
              )}
              {displayDocumentNumberSearch ? (
                <GridItem>
                  <FormControl
                    width="full"
                    isInvalid={
                      (formik.touched.documentNumber ?? false) &&
                      formik.errors.documentNumber != null
                    }
                  >
                    <FormLabel htmlFor="document-number">
                      Número de documento
                    </FormLabel>
                    <Input
                      id="document-number"
                      name="documentNumber"
                      type="number"
                      value={formik.values.documentNumber}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      isDisabled={queryStatus === COMPLETE}
                    />
                    <FormErrorMessage>
                      {formik.errors.documentNumber}
                    </FormErrorMessage>
                  </FormControl>
                </GridItem>
              ) : (
                ''
              )}
              {queryStatus === COMPLETE && queryResult === NOT_FOUND ? (
                <Text>
                  No te pudimos encontrar con los datos que nos brindaste. Por
                  favor intentalo de nuevo.
                </Text>
              ) : null}
            </SimpleGrid>
            {isMobile ? (
              <VStack
                alignContent="center"
                width={['full', 'full', '30%']}
                paddingTop={3}
              >
                {searchButtons()}
              </VStack>
            ) : (
              <HStack
                alignContent="center"
                width="full"
                paddingTop={3}
              >
                {searchButtons()}
              </HStack>
            )}
          </VStack>
        </VStack>
      </form>
      {queryStatus === COMPLETE && queryResult === FOUND ? (
        <PartnerDataBox member={partnerData} />
      ) : null}
    </Box>
  );
}

export default FirstLogin;
