import React, { useEffect, useState } from 'react';
import {
  Heading,
  VStack,
  Text,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  AccordionItem,
  Accordion,
  AccordionButton,
  AccordionIcon,
  Box,
  AccordionPanel,
  Td,
  Table,
  Thead,
  Th,
  TableContainer, Tbody, Tr, Checkbox, HStack, Button, Badge
} from "@chakra-ui/react";
import MemberActivityDetails from '../components/member-activity-details';
import { useAuth } from '../components/auth';
import { getMember } from '../services/member-service';
import { Activity } from '../models/activity';
import { getActivitiesFeesByMember } from "../services/product-service";
import { ActivityProductPayment } from "../models/activities-fees-by-member-response";
import { HTTP_STATUS_CODE_OK } from "../constants";
import { isMobile } from "react-device-detect";
import { PeriodicProduct } from "../models/periodic-product";
import { useNavigate } from "react-router-dom";
import CurrencyData from "../components/currency-data";
import {PeriodicProductPayment} from "../models/periodic-product-payment";
import {badgeColor, getBadgeLabel, getDate, paymentCheckboxIsDisabled} from "../helpers";
import {ProductToPay} from "../models/product-to-pay";

function MyActivities(): JSX.Element {
  const auth = useAuth();
  const navigate = useNavigate();
  const [memberActivities, setMemberActivities] = useState<Activity[]>([]);
  const [memberActivitiesNoInsurance, setMemberActivitiesNoInsurance] = useState<Activity[]>([]);
  const [activitiesFeesYears, setActivitiesFeesYears] = useState<string[]>([]);
  const [activitiesFeesPayments, setActivitiesFeesPayments] = useState(new Map<string, ActivityProductPayment[]>());
  const [insuranceProductsPaid, setInsuranceProductsPaid] = useState<PeriodicProduct[]>([]);
  const [activitiesFeesToPayPayments, setActivitiesFeesToPayPayments] = useState<PeriodicProductPayment[]>([]);
  const [subtitleText, setSubtitleText] = useState("");

  useEffect(() => {
    const getMemberActivities = async (): Promise<void> => {
      const member = await getMember(auth?.user?.selectedMember?.id);
      if (member != null) {
        member.activities.length > 0
          ? setSubtitleText("Estas son las actividades en las que te estás dado de alta.")
          : setSubtitleText("Actualmente no estás dado de alta en ninguna actividad.");

        setMemberActivities(member?.activities);
        setMemberActivitiesNoInsurance(member?.activities.filter((activity) => !activity.isInsurance));
        const activitiesFeesResponse = await getActivitiesFeesByMember(member.id);
        if (activitiesFeesResponse.statusCode === HTTP_STATUS_CODE_OK && activitiesFeesResponse.response) {
          const keys = Object.keys(activitiesFeesResponse.response.activitiesFeesPayments);
          const entries = Object.entries(activitiesFeesResponse.response.activitiesFeesPayments);
          const map = new Map(entries);
          setActivitiesFeesYears(keys.reverse());
          setActivitiesFeesPayments(map);
          setInsuranceProductsPaid(getListOfPaidInsuranceProducts(map, member?.activities) ?? []);
        }
      }
    }

    getMemberActivities().catch(() => {});
  }, [auth?.user?.member?.id]);

  const getListOfPaidInsuranceProducts = (map: Map<string, ActivityProductPayment[]>, memberActivities: Activity[]) => {
    const insuranceActivity = memberActivities.find((insurance) => insurance.isInsurance);
    if (!insuranceActivity) return;

    const list: PeriodicProduct[] = [];
    map.forEach((activityProductPaymentArray) => {
      activityProductPaymentArray.map((activityProductPayment) => {
        if (activityProductPayment.activityId === insuranceActivity.id) {
          activityProductPayment.productPayments.map((productPayment): void => {
            if (productPayment.payments && productPayment.payments.length > 0) {
              list.push(productPayment.product);
            }
          })
        }
      })
    });
    return list;
  }

  const getInsuranceProductToPay = (insuranceActivityId: string, startDate: Date, endDate: Date) => {
    const year = new Date(startDate).getUTCFullYear().toString();
    const insuranceActivityPayments = activitiesFeesPayments.get(year)?.find((activityProductPayment) => activityProductPayment.activityId === insuranceActivityId);
    return insuranceActivityPayments?.productPayments.find((productPayment) => productPayment.product.startDate >= startDate && productPayment.product.endDate <= endDate)?.product;
  }

  const getInsuranceProductToPayPayment = (insuranceActivityId: string, startDate: Date, endDate: Date) => {
    const year = new Date(startDate).getUTCFullYear().toString();
    const insuranceActivityPayments = activitiesFeesPayments.get(year)?.find((activityProductPayment) => activityProductPayment.activityId === insuranceActivityId);
    return insuranceActivityPayments?.productPayments.find((productPayment) => productPayment.product.startDate >= startDate && productPayment.product.endDate <= endDate);
  }

  const handleCheckboxChange = (activityId: string, periodicProductCheckbox: PeriodicProductPayment) => {
    const index = activitiesFeesToPayPayments.findIndex((periodicProductPayment) => periodicProductPayment.product.id === periodicProductCheckbox.product.id);
    const activity = memberActivities.find((activity) => activity.id === activityId);
    if (!activity) return;

    let paidInsuranceIndex = -1; // List of already paid insurance products
    let toPayInsuranceIndex = -1; // List of insurance products going to be paid
    let insuranceActivity: Activity | undefined;
    if (activity.isInsuranceRequired) {
      insuranceActivity = memberActivities.find((insurance) => insurance.isInsurance);
      if (!insuranceActivity) return;

      paidInsuranceIndex = insuranceProductsPaid.findIndex((insuranceProduct) => insuranceProduct.activityId === insuranceActivity?.id && insuranceProduct.startDate >= periodicProductCheckbox.product.startDate && insuranceProduct.endDate <= periodicProductCheckbox.product.endDate);
      toPayInsuranceIndex = activitiesFeesToPayPayments.findIndex((insuranceProductPayment) => insuranceProductPayment.product.activityId === insuranceActivity?.id && insuranceProductPayment.product.startDate >= periodicProductCheckbox.product.startDate && insuranceProductPayment.product.endDate <= periodicProductCheckbox.product.endDate);
    }


    if (index >= 0) {
      setActivitiesFeesToPayPayments(activitiesFeesToPayPayments.filter((ppp) => ppp.product.id !== periodicProductCheckbox.product.id));
      if (activity.isInsuranceRequired && toPayInsuranceIndex >= 0 && insuranceActivity) {
        const insuranceProduct = getInsuranceProductToPay(insuranceActivity.id, periodicProductCheckbox.product.startDate, periodicProductCheckbox.product.endDate);
        if (insuranceProduct) {
          setActivitiesFeesToPayPayments(activitiesFeesToPayPayments.filter((ppp) => ppp.product.id !== periodicProductCheckbox.product.id && ppp.product.id !== insuranceProduct.id));
        }
      }
    }
    else {
      setActivitiesFeesToPayPayments([...activitiesFeesToPayPayments, periodicProductCheckbox]);
      if (activity.isInsuranceRequired) {
        if (paidInsuranceIndex < 0 && toPayInsuranceIndex < 0 && insuranceActivity) {
          const insuranceProduct = getInsuranceProductToPay(insuranceActivity.id, periodicProductCheckbox.product.startDate, periodicProductCheckbox.product.endDate);
          const insuranceProductPayment = getInsuranceProductToPayPayment(insuranceActivity.id, periodicProductCheckbox.product.startDate, periodicProductCheckbox.product.endDate);
          if (insuranceProduct && insuranceProductPayment) {
            setActivitiesFeesToPayPayments([...activitiesFeesToPayPayments, periodicProductCheckbox, insuranceProductPayment]);
          }
        }
      }
    }
  }

  const getUpdatedPrice = (periodicProductPayment: PeriodicProductPayment) =>
    periodicProductPayment.isPastDue && periodicProductPayment.isApplicable && periodicProductPayment.totalPriceToPay >= periodicProductPayment.remainingPriceToPay
      ? periodicProductPayment.totalPriceToPay
      : periodicProductPayment.product.price;

  const showUpdatedPrice = (periodicProductPayment: PeriodicProductPayment) => {
    const updatedPrice = getUpdatedPrice(periodicProductPayment);
    return updatedPrice !== periodicProductPayment.product.price
      ? <CurrencyData value={updatedPrice}/>
      : <></>
  }

  const getAccordionItemTableRows = (year: string, activityId: string) => {
    const activityFees = activitiesFeesPayments.get(year)?.find((activityFee) => activityFee.activityId === activityId);

    return activityFees?.productPayments.map((activityFee) => {
      if (activityFee.isApplicable)
        return (
          <Tr key={`${activityFee.product.id}-${year}`}>
            <Td>{ isMobile ? activityFee.product.shortName : activityFee.product.name }</Td>
            <Td><CurrencyData value={activityFee.product.price}/></Td>
            <Td><Badge colorScheme={badgeColor(activityFee)}>{getBadgeLabel(activityFee)}</Badge></Td>
            <Td>{activityFee.product.paymentDetails?.map(pd => <>{getDate(pd.payment?.date, 'dd-mm-yyyy HH:MM:ss', false)}<br/></>)}</Td>
            <Td>{showUpdatedPrice(activityFee)}</Td>
            <Td>{activityFee.product.paymentDetails?.map(pd => <><CurrencyData value={pd.price}/><br/></>)}</Td>
            <Td><Checkbox borderColor="black" disabled={paymentCheckboxIsDisabled(activityFee)} onChange={() => { handleCheckboxChange(activityId, activityFee) }}></Checkbox></Td>
          </Tr>
        )
    })

  }

  const getAccordionItemTable = (year: string, activityId: string) => {
    return (
      <TableContainer>
        <Table variant='simple'>
          <Thead>
            <Tr>
              <Th>Detalle</Th>
              <Th>Valor original</Th>
              <Th>Estado</Th>
              <Th>Fecha de pago</Th>
              <Th>Valor actualizado</Th>
              <Th>Valor abonado</Th>
              <Th>Abonar</Th>
            </Tr>
          </Thead>
          <Tbody>
            {getAccordionItemTableRows(year, activityId)}
          </Tbody>
        </Table>
      </TableContainer>
    )
  }

  const getAccordionItems = (activityId: string) => {
    return activitiesFeesYears.map((year) => {
      if (!activitiesFeesPayments.get(year)?.find((app) => app.productPayments.find((pp) => pp.isApplicable))) return;
      return (
        <AccordionItem key={`${activityId}-${year}`} width="fit-content">
          <h2>
            <AccordionButton>
              <Box as="span" flex='1' textAlign='left'>{year}</Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4}>
            {getAccordionItemTable(year, activityId)}
          </AccordionPanel>
        </AccordionItem>
      )
    });
  }

  const getActivitiesYearsAccordion = (activityId: string) => {
    return (
      <Accordion defaultIndex={[0]} allowMultiple width={["100%", "100%", "100%"]} alignSelf="flex-start">
        {getAccordionItems(activityId)}
      </Accordion>
    )
  }

  const getTabsTitles = () => {
    return memberActivitiesNoInsurance.map((activity) => <Tab key={activity.id}>{activity.name}</Tab>);
  }

  const getTabsPanels = () => {
    return memberActivitiesNoInsurance.map((activity) => (
      <TabPanel key={activity.id}>
        <VStack>
          <MemberActivityDetails activity={activity} />
          <Text as="b" fontSize="lg" marginTop="1em" alignSelf="flex-start">Pagos</Text>
          {getActivitiesYearsAccordion(activity.id)}
        </VStack>
      </TabPanel>
    ));
  }

  const getActivitiesTabs = () => {
    return (
      <Tabs variant='soft-rounded' colorScheme='green' width="100%">
        <TabList alignSelf="start">
          {getTabsTitles()}
        </TabList>
        <TabPanels>
          {getTabsPanels()}
        </TabPanels>
      </Tabs>
    )
  }

  const getPaymentTableRows = () => {
    return activitiesFeesToPayPayments.map((activityFeePayment) => {
      return (
        <Tr key={activityFeePayment.product.id}>
          <Td>{activityFeePayment.product.name}</Td>
          <Td><CurrencyData value={activityFeePayment.remainingPriceToPay}/></Td>
        </Tr>
      )
    })
  }

  const handlePayButton = (): void => {
    const productsToPay: ProductToPay[] = [];
    activitiesFeesToPayPayments.map(afp => productsToPay.push({
      productId: afp.product.id!,
      name: afp.product.name,
      description: afp.product.description,
      price: afp.remainingPriceToPay,
      periodicProductPayment: afp
    }))
    navigate('/payment-page', {
      state: { productsToPay: productsToPay, paymentMethod: 'MercadoPago' }
    });
  };

  const getPaymentSection = () => {
    if (activitiesFeesToPayPayments.length === 0) return <></>

    return (
      <HStack width={['full', 'full', '100%']} align="flex-start" spacing={3}>
        <VStack>
          <Heading>Abonar</Heading>
          <TableContainer>
            <Table variant='simple'>
              <Thead>
                <Tr>
                  <Th>Detalle</Th>
                  <Th>Valor</Th>
                </Tr>
              </Thead>
              <Tbody>
                {getPaymentTableRows()}
              </Tbody>
            </Table>
          </TableContainer>
        </VStack>
        <Button colorScheme="blue" onClick={handlePayButton}>
          MercadoPago
        </Button>
      </HStack>
    )
  }

  return (
    <VStack
      width="80%"
      alignContent="flex-start"
      spacing={3}
      paddingRight={[null, null, 10]}
    >
      <VStack width={['full', 'full', '100%']} align="flex-start" spacing={3}>
        <Heading>Mis actividades</Heading>
        <Text>{subtitleText}</Text>
      </VStack>
      <VStack width={['full', 'full', '100%']} align="flex-start" spacing={3}>
        {getActivitiesTabs()}
      </VStack>
      {getPaymentSection()}
    </VStack>
  )
}

export default MyActivities;
