import React, { useEffect } from 'react';
import { Radio, RadioGroup, Stack } from '@chakra-ui/react';
import { isMobile } from 'react-device-detect';

interface Props {
  isDisabled: boolean;
  callback: any;
}

function RadioButtons(props: Props): JSX.Element {
  const [value, setValue] = React.useState('1');
  const [disabled, setDisabled] = React.useState(props.isDisabled);

  useEffect(() => {
    setDisabled(props.isDisabled);
  }, [props.isDisabled]);

  const radioGroupChange = (value: string): void => {
    setValue(value);
    props.callback(value);
  };

  return (
    <RadioGroup width={['90%', '90%']} onChange={radioGroupChange} value={value}>
      <Stack direction="row">
        <Radio width={['50%', '50%']} value="1" isDisabled={disabled}>
          { isMobile ? "N° socio" : "Número de socio" }
        </Radio>
        <Radio width={['50%', '50%']} value="2" isDisabled={disabled}>
          { isMobile ? "N° documento" : "Número de documento" }
        </Radio>
      </Stack>
    </RadioGroup>
  );
}

export default RadioButtons;
