import {
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  Input,
  SimpleGrid,
  Text,
  useToast,
  VStack
} from '@chakra-ui/react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import React, { useEffect, useState } from 'react';
import {
  HTTP_STATUS_CODE_OK,
  TOAST_ERROR,
  TOAST_ERROR_TITLE,
  TOAST_PARTNER_DATA_UPDATE_DESCRIPTION_ERROR,
  TOAST_PARTNER_DATA_UPDATE_DESCRIPTION_SUCCESS,
  TOAST_SUCCESS,
  TOAST_SUCCESS_TITLE
} from '../constants';
import { getMember, updateMemberData } from '../services/member-service';
import ConfirmationDialog from '../components/confirmation-dialog';
import { Member } from '../models/member';
import { AlertStatus } from '@chakra-ui/alert';
import { isMobile } from 'react-device-detect';
import { useAuth } from '../components/auth';
import { Auth } from '../models/auth';

function MyData(): JSX.Element {
  const auth: Auth = useAuth();
  const [member, setMember] = useState<Member>();
  const toast = useToast();

  useEffect(() => {
    const getMemberData = async (): Promise<void> => {
      if (!auth.user?.selectedMember) { return; }
      const member = await getMember(auth.user.selectedMember?.id);
      if (member != null) {
        setMember(member);
      }
    };

    getMemberData().catch(() => {});
  }, []);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: member?.id,
      firstName: member?.firstName,
      lastName: member?.lastName,
      documentNumber: member?.documentNumber,
      gender: member?.gender?.value ?? '',
      memberNumber: member?.memberNumber,
      membershipDate: new Date(
        member != null ? member?.membershipDate : Date.now()
      )?.toISOString()
        .split('T')[0],
      type: member?.memberType?.value,
      status: member?.status?.value,
      birthdate: new Date(
        member != null ? member?.birthdate : Date.now()
      )?.toISOString()
        .split('T')[0],
      address: {
        street: member?.contact?.street,
        number: member?.contact?.number,
        floor: member?.contact?.floor,
        apartment: member?.contact?.apartment,
        postcode: member?.contact?.postcode,
        city: member?.contact?.city,
        state: member?.contact?.state,
        country: member?.contact?.country,
        homePhoneNumber: member?.contact?.homePhoneNumber,
        mobilePhoneNumber: member?.contact?.mobilePhoneNumber,
        email: member?.contact?.email
      },
    },
    validationSchema: Yup.object({}),
    onSubmit: async () => {
      const user = auth.user;
      const memberUpdate = formik.values as unknown as Member;

      const response = await updateMemberData(
        member?.id ?? '',
        memberUpdate
      );

      auth.setUser({...user, member: memberUpdate });

      let title = TOAST_ERROR_TITLE;
      let description = TOAST_PARTNER_DATA_UPDATE_DESCRIPTION_ERROR;
      let status: AlertStatus = TOAST_ERROR;
      if (response?.status === HTTP_STATUS_CODE_OK) {
        title = TOAST_SUCCESS_TITLE;
        description = TOAST_PARTNER_DATA_UPDATE_DESCRIPTION_SUCCESS;
        status = TOAST_SUCCESS;
      }

      toast({
        title,
        description,
        status,
        duration: 9000,
        isClosable: true,
        position: 'bottom-right'
      });
    }
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <VStack
        width="full"
        alignContent="center"
        spacing={3}
        paddingTop={[null, null, 10]}
        paddingLeft={[null, null, 10]}
        paddingRight={[null, null, 10]}
      >
        <VStack width={['full', 'full', '60%']} align="flex-start" spacing={3}>
          <Heading>Mis datos</Heading>
          <Text>Estos son tus datos. Si detectás un error en un dato no editable, por favor comunicate con nosotros así lo podemos modificar por vos.</Text>
        </VStack>

        <VStack width={['full', 'full', '60%']} align="flex-start" spacing={3}>
          <Heading as="h3" size="lg">
            Datos personales
          </Heading>

          <SimpleGrid width="full" spacing={5} columns={[3, 3, 2]}>
            <GridItem colSpan={[2, 2, 1]}>
              <FormControl>
                <FormLabel>Nombres</FormLabel>
                <Input
                  id="first-name"
                  name="firstName"
                  type="text"
                  value={formik.values.firstName}
                  onChange={formik.handleChange}
                  isDisabled
                />
              </FormControl>
            </GridItem>
            <GridItem colSpan={[2, 2, 1]}>
              <FormControl>
                <FormLabel>Apellidos</FormLabel>
                <Input
                  id="last-name"
                  name="lastName"
                  type="text"
                  value={formik.values.lastName}
                  onChange={formik.handleChange}
                  isDisabled
                />
              </FormControl>
            </GridItem>
          </SimpleGrid>
          <SimpleGrid width="full" spacing={5} columns={[3, 3, 3]}>
            <GridItem colSpan={[2, 2, 1]}>
              <FormControl>
                <FormLabel>
                  {isMobile ? 'Documento' : 'Número de documento'}
                </FormLabel>
                <Input
                  id="document-number"
                  name="documentNumber"
                  type="number"
                  value={formik.values.documentNumber}
                  onChange={formik.handleChange}
                  isDisabled
                />
              </FormControl>
            </GridItem>
            <GridItem colSpan={[2, 2, 1]}>
              <FormControl>
                <FormLabel>
                  {isMobile ? 'Fecha nac.' : 'Fecha de nacimiento'}
                </FormLabel>
                <Input
                  id="birthdate"
                  name="birthdate"
                  type="date"
                  value={formik.values.birthdate}
                  onChange={formik.handleChange}
                  isDisabled
                />
              </FormControl>
            </GridItem>
            <GridItem colSpan={[2, 2, 1]}>
              <FormControl>
                <FormLabel>Género</FormLabel>
                <Input
                  id="gender"
                  name="gender"
                  type="text"
                  value={formik.values.gender}
                  onChange={formik.handleChange}
                  isDisabled
                />
              </FormControl>
            </GridItem>
          </SimpleGrid>

          <Heading as="h3" size="lg">
            Datos de socio
          </Heading>

          <SimpleGrid width="full" spacing={5} columns={[3, 3, 3, 4]}>
            <GridItem colSpan={1}>
              <FormControl>
                <FormLabel>N° socio</FormLabel>
                <Input
                  id="member-number"
                  name="memberNumber"
                  type="number"
                  value={formik.values.memberNumber}
                  onChange={formik.handleChange}
                  isDisabled
                />
              </FormControl>
            </GridItem>
            <GridItem colSpan={[2, 2, 1]}>
              <FormControl>
                <FormLabel>{isMobile ? 'Fecha asoc.' : 'Fecha de asociación'}</FormLabel>
                <Input
                  id="membership-date"
                  name="membershipDate"
                  type="date"
                  value={formik.values.membershipDate}
                  onChange={formik.handleChange}
                  isDisabled
                />
              </FormControl>
            </GridItem>
            <GridItem colSpan={[2, 2, 1]}>
              <FormControl>
                <FormLabel>{isMobile ? 'Tipo' : 'Tipo de socio'}</FormLabel>
                <Input
                  id="membership-type"
                  name="type"
                  type="text"
                  value={formik.values.type}
                  onChange={formik.handleChange}
                  isDisabled
                />
              </FormControl>
            </GridItem>
            <GridItem colSpan={[2, 2, 1]}>
              <FormControl>
                <FormLabel>Estado</FormLabel>
                <Input
                  id="membership-status"
                  name="status"
                  type="text"
                  value={formik.values.status}
                  onChange={formik.handleChange}
                  isDisabled
                />
              </FormControl>
            </GridItem>
          </SimpleGrid>

          <Heading as="h3" size="lg">
            Datos de contacto
          </Heading>

          <Heading as="h4" size="md">
            Dirección
          </Heading>

          <Grid width="full" templateColumns={['repeat(3, 1fr)', 'repeat(5, 1fr)']} gap={5}>
            <GridItem colSpan={[2, 2, 2]}>
              <FormControl>
                <FormLabel>Calle</FormLabel>
                <Input
                  id="street"
                  name="address.street"
                  type="text"
                  value={formik.values.address.street}
                  onChange={formik.handleChange}
                />
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel>Número</FormLabel>
                <Input
                  id="number"
                  name="address.number"
                  type="number"
                  value={formik.values.address.number}
                  onChange={formik.handleChange}
                />
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel>Piso</FormLabel>
                <Input
                  id="floor"
                  name="address.floor"
                  type="text"
                  value={formik.values.address.floor}
                  onChange={formik.handleChange}
                />
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel>Depto</FormLabel>
                <Input
                  id="apartment"
                  name="address.apartment"
                  type="text"
                  value={formik.values.address.apartment}
                  onChange={formik.handleChange}
                />
              </FormControl>
            </GridItem>
            <GridItem colSpan={2}>
              <FormControl>
                <FormLabel>Localidad</FormLabel>
                <Input
                  id="city"
                  name="address.city"
                  type="text"
                  value={formik.values.address.city}
                  onChange={formik.handleChange}
                />
              </FormControl>
            </GridItem>
            <GridItem colSpan={1}>
              <FormControl>
                <FormLabel>{isMobile ? 'C. Postal' : 'Cód. postal'}</FormLabel>
                <Input
                  id="postcode"
                  name="address.postcode"
                  type="text"
                  value={formik.values.address.postcode}
                  onChange={formik.handleChange}
                />
              </FormControl>
            </GridItem>
            <GridItem colSpan={2}>
              <FormControl>
                <FormLabel>Provincia</FormLabel>
                <Input
                  id="state"
                  name="address.state"
                  type="text"
                  value={formik.values.address.state}
                  onChange={formik.handleChange}
                />
              </FormControl>
            </GridItem>
            <GridItem colSpan={2}>
              <FormControl>
                <FormLabel>País</FormLabel>
                <Input
                  id="country"
                  name="address.country"
                  type="text"
                  value={formik.values.address.country}
                  onChange={formik.handleChange}
                />
              </FormControl>
            </GridItem>
            <GridItem colSpan={1}>
              <FormControl>
                <FormLabel>
                  {isMobile ? 'Tel.' : 'Teléfono fijo'}
                </FormLabel>
                <Input
                  id="home-phone-number"
                  name="address.homePhoneNumber"
                  type="text"
                  value={formik.values.address.homePhoneNumber}
                  onChange={formik.handleChange}
                />
              </FormControl>
            </GridItem>
            <GridItem colSpan={1}>
              <FormControl>
                <FormLabel>
                  {isMobile ? 'Cel.' : 'Celular'}
                </FormLabel>
                <Input
                  id="mobile-phone-number"
                  name="address.mobileNumber"
                  type="text"
                  value={formik.values.address.mobilePhoneNumber}
                  onChange={formik.handleChange}
                />
              </FormControl>
            </GridItem>
          </Grid>

          <Grid width="full" templateColumns={['repeat(3, 1fr)', 'repeat(5, 1fr)']} gap={5}>
            <GridItem colSpan={[2, 2, 2]}>
              <FormControl>
                <FormLabel>Correo electrónico</FormLabel>
                <Input
                  id="email"
                  name="address.email"
                  type="text"
                  value={formik.values.address.email}
                  onChange={formik.handleChange}
                />
              </FormControl>
            </GridItem>
          </Grid>
        </VStack>
        <VStack align="center" padding={5}>
          <ConfirmationDialog
            buttonLabel="Modificar datos"
            dialogHeader="Confirmar modificación"
            dialogBody="¿Estás seguro que querés modificar tus datos?"
            submitAction={formik.submitForm}
          />
        </VStack>
      </VStack>
    </form>
  );
}

export default MyData;
