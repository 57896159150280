import { Payment } from "./payment";
import { PeriodicProduct } from "./periodic-product";
import {PAYMENT_VALIDATION_STATUS_PENDING, PAYMENT_VALIDATION_STATUS_VALIDATION} from "../constants";

export interface PeriodicProductPayment {
  product: PeriodicProduct;
  payments: Payment[];
  isApplicable: boolean;
  isPastDue: boolean;
  totalPriceToPay: number;
  remainingPriceToPay: number;
  manualCheckStatusId: string | null;
  isCompleted: boolean;
}

export const isInVerificationProcess = (product: PeriodicProductPayment): boolean => product.manualCheckStatusId === PAYMENT_VALIDATION_STATUS_PENDING || product.manualCheckStatusId === PAYMENT_VALIDATION_STATUS_VALIDATION;

export const requiresShowingProductExtraCharge = (product: PeriodicProductPayment) => product.isApplicable && product.isPastDue
