import dateFormat, { i18n } from "dateformat";
import {isInVerificationProcess, PeriodicProductPayment} from "./models/periodic-product-payment";
import {
  PAYMENT_VALIDATION_STATUS_ERROR,
  PAYMENT_VALIDATION_STATUS_PENDING,
  PAYMENT_VALIDATION_STATUS_VALIDATION
} from "./constants";

// DATES

i18n.dayNames = [
  "Dom.",
  "Lun.",
  "Mar.",
  "Miér.",
  "Juev.",
  "Vier.",
  "Sáb.",
  "Domingo",
  "Lunes",
  "Martes",
  "Miércoles",
  "Jueves",
  "Viernes",
  "Sábado",
];

i18n.monthNames = [
  "Ene",
  "Feb",
  "Mar",
  "Abr",
  "May",
  "Jun",
  "Jul",
  "Ago",
  "Sep",
  "Oct",
  "Nov",
  "Dic",
  "Enero",
  "Febrero",
  "Marzo",
  "Abril",
  "Mayo",
  "Junio",
  "Julio",
  "Agosto",
  "Septiembre",
  "Octubre",
  "Noviembre",
  "Diciembre",
];

const months = [ "Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre" ];

export const getDate = (date: Date | undefined, format: string, utc: boolean = false) => {
  if (!date) {
    return null;
  }

  return dateFormat(date, format, utc);
}

export const openLinkInNewTab = (url: string) => {
  window.open(url, "_blank", "noreferrer");
};

export const badgeColor = (periodicProductPayment: PeriodicProductPayment): string => {
  if (isInVerificationProcess(periodicProductPayment)) return 'yellow';

  return periodicProductPayment.isCompleted
    ? 'green'
    : (periodicProductPayment.isApplicable && periodicProductPayment.isPastDue) || periodicProductPayment.manualCheckStatusId === PAYMENT_VALIDATION_STATUS_ERROR
      ? 'red'
      : 'blue'
}

export const getBadgeLabel = (periodicProductPayment: PeriodicProductPayment): string => {
  if (isInVerificationProcess(periodicProductPayment)) return 'En verificación';
  if (periodicProductPayment.manualCheckStatusId === PAYMENT_VALIDATION_STATUS_ERROR) return 'Verificación errónea'

  return periodicProductPayment.isCompleted
    ? 'Abonada'
    : 'Sin abonar'
}

export const paymentCheckboxIsDisabled = (periodicProductPayment: PeriodicProductPayment): boolean => {
  if (periodicProductPayment.isCompleted || periodicProductPayment.remainingPriceToPay === 0) return true;

  return periodicProductPayment.manualCheckStatusId !== null && (periodicProductPayment.manualCheckStatusId === PAYMENT_VALIDATION_STATUS_PENDING || periodicProductPayment.manualCheckStatusId === PAYMENT_VALIDATION_STATUS_VALIDATION);
}

export const manualCheckStatusNotPayable = (periodicProductPayment: PeriodicProductPayment): boolean => periodicProductPayment.manualCheckStatusId !== null && (periodicProductPayment.manualCheckStatusId === PAYMENT_VALIDATION_STATUS_PENDING || periodicProductPayment.manualCheckStatusId === PAYMENT_VALIDATION_STATUS_VALIDATION);
