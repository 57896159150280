import {
  Button, FormControl, FormErrorMessage, FormLabel,
  GridItem,
  Heading, Input,
  SimpleGrid, Stack,
  Text,
  VStack
} from "@chakra-ui/react";
import {Form, Formik} from "formik";
import React, {useState} from "react";
import * as Yup from "yup";
import {sendPasswordResetEmail} from "../services/user-service";

function ForgotPassword() {
  const [showMessage, setShowMessage] = useState(false);
  const [requestCompleted, setRequestCompleted] = useState(false);

  return (
    <Formik
      initialValues={{
        email: ''
      }}
      validationSchema={
        Yup.object({
          email: Yup.string()
            .email('Ingresá una dirección de correo electrónico válida.')
            .required('Necesitamos saber tu dirección de correo electrónico.'),
        })
      }
      onSubmit={(values, { setSubmitting }) => {
        setTimeout(async () => {
          const response = await sendPasswordResetEmail(values.email);
          if (response) {
            setRequestCompleted(true);
            setShowMessage(true);
          }

          setSubmitting(false);
        }, 400);
      }}
    >
      {
        formik => (
          <VStack width="full" spacing={3}>
            <VStack
              width={['full', 'full', '80%']}
              alignItems="flex-start"
              spacing={3}
            >
              <Heading>Olvidé mi contraseña</Heading>
              <Text>¿Te olvidaste la contraseña para acceder a la sede virtual? ¡No te preocupes! A todos nos pasó alguna vez 😉</Text>
              <Text>Escribí tu casilla de correo electrónico e iniciá el proceso para restablecer tu contraseña. Va a ser muy rápido.</Text>
              <Text>Luego de escribir tu dirección de correo electrónico, apretá el botón "Enviar enlace" y chequeá tu casilla de e-mail, ya que recibirás un correo. Ahí vas a encontrar un enlace para poder restablecer la contraseña de tu cuenta. ¡Asegurate de escribir la dirección de correo electrónico con la que te registraste originalmente! Tené en cuenta que el enlace dura una hora. Pasado este tiempo, tendrás que repetir el proceso para solicitar uno nuevo.</Text>
            </VStack>
            <VStack
              width={['full', 'full', '80%']}
              alignItems="flex-start"
            >
              <Form style={{ width: '100%' }}>
                <Stack width={['full', 'full', '40%']}>
                  <SimpleGrid spacing={3}>
                    <GridItem>
                      <FormControl
                        isInvalid={
                          (formik.touched.email ?? false) &&
                          formik.errors.email != null
                        }
                      >
                        <FormLabel>Correo electrónico</FormLabel>
                          <Input
                            id="email"
                            name="email"
                            type={"text"}
                            value={formik.values.email}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />
                        <FormErrorMessage>{formik.errors.email}</FormErrorMessage>
                      </FormControl>
                    </GridItem>
                  </SimpleGrid>
                  <GridItem marginTop={5}>
                    <Button type="submit" width="full" colorScheme="green" disabled={requestCompleted}>
                      Enviar enlace
                    </Button>
                    {
                      showMessage
                        ? <Text>Se ha enviado un correo electrónico a {formik.values.email} para restablecer la contraseña de la cuenta. ¡Recordá que el enlace dura una hora!</Text>
                        : null
                    }
                  </GridItem>
                </Stack>
              </Form>
            </VStack>
          </VStack>
        )
      }
    </Formik>
  )
}

export default ForgotPassword;
