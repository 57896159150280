import {Button, Heading, Text, VStack} from "@chakra-ui/react";
import React from "react";
import {useNavigate} from "react-router-dom";
import {ProductToPay} from "../models/product-to-pay";
import {PAYMENT_PAGE} from "../constants";

interface Props {
  membershipFeesToPayList: ProductToPay[];
}

export default function PaymentMethods(props: Props) {
  const navigate = useNavigate();

  const handleMercadoPagoButton = (): void => {
    navigate(PAYMENT_PAGE, {
      state: { productsToPay: props.membershipFeesToPayList, paymentMethod: 'MercadoPago' }
    });
  };

  const handleBankTransferButton = (): void => {
    navigate(PAYMENT_PAGE, {
      state: { productsToPay: props.membershipFeesToPayList, paymentMethod: 'BankTransfer' }
    });
  };

  return (
    <VStack alignItems="flex-start">
      <Heading as="h2" size="md">
        Métodos de pago
      </Heading>
      <Text>
        Podés abonar tus cuotas sociales pendientes de pago a través de los
        siguientes medios de pago. Es rápido, simple y seguro.
      </Text>
      <VStack marginTop={3} paddingTop={3} alignItems="flex-start">
        <Button colorScheme="blue" onClick={handleMercadoPagoButton}>
          MercadoPago
        </Button>
        <Button colorScheme="blue" onClick={handleBankTransferButton}>
          Transferencia bancaria
        </Button>
      </VStack>
    </VStack>
  );
}
