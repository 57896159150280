import React, { useEffect, useState } from 'react';
import { useMercadopago } from 'react-sdk-mercadopago';
import { MERCADO_PAGO_PUBLIC_KEY } from '../constants';
import { Box } from '@chakra-ui/react';
import { getMercadoPagoPreferenceId } from '../services/payment-service';
import {
  ProductToPay,
  ProductsPayment
} from '../models/product-to-pay';

interface Props {
  memberId: string;
  memberNumber: number;
  productsToPay: ProductToPay[];
}

function MercadoPago(props: Props): JSX.Element {
  const [isPreferenceReady, setIsPreferenceReady] = useState(false);
  const mercadoPago = useMercadopago.v2(MERCADO_PAGO_PUBLIC_KEY, {
    locale: 'es-AR'
  });

  useEffect(() => {
    if (
      props.productsToPay == null ||
      props.productsToPay.length === 0 ||
      isPreferenceReady ||
      mercadoPago == null
    ) {
      return undefined;
    }

    if (!isPreferenceReady) {
      const mercadoPagoPaymentData: ProductsPayment = {
        memberId: props.memberId,
        memberNumber: props.memberNumber,
        products: props.productsToPay
      };

      getMercadoPagoPreferenceId(mercadoPagoPaymentData).then(
        (response) => {
          if (mercadoPago != null) {
            mercadoPago.checkout({
              preference: {
                id: response
              },
              render: {
                container: '.mercado-pago-container',
                label: 'Pagar con MercadoPago'
              }
            });
            setIsPreferenceReady(true);
          }
        },
        () => {}
      );
    }
  }, [
    mercadoPago,
    isPreferenceReady,
    props.memberId,
    props.memberNumber,
    props.productsToPay
  ]);

  return <Box className="mercado-pago-container" />;
}

export default MercadoPago;
