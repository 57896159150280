import { User } from '../models/user';

export const getUser = (): User | null => {
  const user = sessionStorage.getItem('user');
  if (user != null) return JSON.parse(user);

  return null;
};

export const getToken = (): string | null => {
  return sessionStorage.getItem('token') ?? null;
};

export const setUserSession = (
  user: any,
  token: string | null = null
): void => {
  sessionStorage.setItem('user', JSON.stringify(user));
  if (token !== null) {
    sessionStorage.setItem('token', token);
  }
};

export const removeUserSession = (): void => {
  sessionStorage.removeItem('user');
  sessionStorage.removeItem('token');
};
