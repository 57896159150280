import { Card, CardBody, CardFooter, CardHeader } from '@chakra-ui/card';
import {
  Avatar,
  Box,
  Center,
  Divider,
  Flex,
  GridItem,
  Heading,
  HStack,
  Image,
  SimpleGrid,
  Stack,
  StackDivider,
  Text, VStack
} from '@chakra-ui/react';
import React from 'react';
import { Member } from '../models/member';
import { GET_MEMBER_DATA_UI_URL, QR_CODE_ENDPOINT } from '../constants';
import { isMobile } from 'react-device-detect';
import { getUserFullName, User } from '../models/user';
import { useAuth } from './auth';

interface Props {
  member: Member;
}

function MemberCredentialCard(props: Props): JSX.Element {
  const auth = useAuth();
  const user: User = auth.user;

  console.log(props.member);

  const generateQrCodeUrl = (): string => {
    return QR_CODE_ENDPOINT.replace(
      '{MemberUrl}',
      GET_MEMBER_DATA_UI_URL.replace('{MemberId}', props.member.id)
    );
  };

  const credentialCardBodyData = (): JSX.Element => (
    <Stack width="full" divider={<StackDivider />} spacing="4">
      <SimpleGrid width="full" spacing={5} columns={2}>
        <GridItem>
          <Heading size="xs" textTransform="uppercase">
            Nombres
          </Heading>
          <Text paddingTop="2" fontSize="md" textTransform="uppercase">
            {props.member.firstName}
          </Text>
        </GridItem>
        <GridItem>
          <Heading size="xs" textTransform="uppercase">
            Apellidos
          </Heading>
          <Text paddingTop="2" fontSize="md" textTransform="uppercase">
            {props.member.lastName}
          </Text>
        </GridItem>
        <GridItem>
          <Heading size="xs" textTransform="uppercase">
            Documento
          </Heading>
          <Text paddingTop="2" fontSize="md" textTransform="uppercase">
            {props.member.documentNumber}
          </Text>
        </GridItem>
        <GridItem>
          <Heading size="xs" textTransform="uppercase">
            {isMobile ? 'Fecha nac.' : 'Fecha de nacimiento'}
          </Heading>
          <Text paddingTop="2" fontSize="md" textTransform="uppercase">
            {
              new Date(props.member.birthdate)
                .toLocaleDateString('es-AR', { timeZone: 'UTC' })
                .split('T')[0]
            }
          </Text>
        </GridItem>
      </SimpleGrid>
    </Stack>
  );

  const credentialCardQrCode = (): JSX.Element => (
    <Center paddingTop={[3, 3, 0]} width="35%" alignContent="center">
      <Image
        src={generateQrCodeUrl()}
        alt="Código QR"
        borderRadius="lg"
        maxWidth={['100px', '130px', '175px']}
        maxHeight={['100px', '130px', '175px']}
      />
    </Center>
  );

  const credentialCardBody = (): JSX.Element => {
    return isMobile
      ? (
        <VStack>
          {credentialCardBodyData()}
          {credentialCardQrCode()}
        </VStack>
      )
      : (
        <HStack>
          {credentialCardBodyData()}
          {credentialCardQrCode()}
        </HStack>
      )
  }

  return (
    <Card
      minWidth="60%"
      maxWidth="lg"
      borderWidth={3}
      borderStyle="solid"
      margin={5}
      variant="elevated"
    >
      <CardHeader>
        <Flex flex="1" gap="4" alignItems="center" flexWrap="nowrap">
          <Avatar
            name={getUserFullName(user)}
            src="https://scontent.faep9-1.fna.fbcdn.net/v/t31.18172-8/12080334_10206917505330222_2378404738627309831_o.jpg?_nc_cat=104&ccb=1-7&_nc_sid=174925&_nc_ohc=Us3znAq0f_gAX9lnrpQ&_nc_ht=scontent.faep9-1.fna&oh=00_AfANR7EUkBVl2hb_RxCsK2vBxlv1gIcX2lUMpbD-Vs_h6w&oe=63D4823C"
            size={['md', 'md', 'lg']}
          />
          <Box>
            <Heading size={['sm', 'sm', 'md']} textTransform="uppercase">
              {props.member.firstName} {props.member.lastName}
            </Heading>
            <Text>
              Socio N° <Text as="b">{props.member.memberNumber}</Text> del{' '}
              <Text as="b">Club Atlético Pacífico</Text>
            </Text>
          </Box>
        </Flex>
      </CardHeader>
      <Divider />
      <CardBody>
        {credentialCardBody()}
      </CardBody>
      <Divider />
      <CardFooter>
        <Box width="50%">
          <Heading size="xs" textTransform="uppercase">
            Categoría
          </Heading>
          <Text paddingTop="2" fontSize="md" textTransform="uppercase">
            {props.member.memberType?.value}
          </Text>
        </Box>
      </CardFooter>
    </Card>
  );
}

export default MemberCredentialCard;
